import React, { useEffect, useState } from 'react';
import TableItemWrapper from '../../../components/BasicTable/TableItemWrapper';
import TableColumn from '../../../components/BasicTable/TableColumn';
import Value from '../../../components/basic/Value';
import LiquidityMiningCard from '../../../components/liquidityMining/LiquidityMiningCard';
import { getAssetInfo, TokenIcon } from '../../../helpers/config/assets-config';

import staticStyles from './MobileVoteItem__style';
import ReactSlider from 'react-slider';
import { useThemeContext } from '@aave/aave-ui-kit';
import styled from 'styled-components';
import { VoteTableItemProps } from './VoteTableItem';
import { useIntl } from 'react-intl';
import messages from '../messages';

const MobileTitle = styled.div`
  font-size: 14px;
`;

export default function MobileVoteItem({
  currencySymbol,
  disabled,
  maxDialutingEmissions,
  baseEmissions,
  setGlobalPercentage,
  globalPercentage,
  nftFactor,
  allocPoint,
  totalAllocPoint,
  totalSupply,
  currentNFT,
  isAToken,
  price,
  klapPrice,
  name,
}: VoteTableItemProps) {
  const intl = useIntl();
  const asset = getAssetInfo(currencySymbol);
  const { sm } = useThemeContext();
  const factor = Number(totalAllocPoint) !== 0 ? Number(allocPoint) / Number(totalAllocPoint) : 1;
  const [percentage, setPercentage] = useState(0);
  const [newEmissions, setNewEmissions] = useState(0);
  const [newAPR, setNewAPR] = useState(0);

  useEffect(() => {
    setPercentage(0);
  }, [currentNFT]);

  useEffect(() => {
    if (Number(totalAllocPoint) === 0 || Number(totalSupply) === 0) {
      return;
    }

    if (globalPercentage === 0) {
      const newEmission = factor * (Number(baseEmissions) + Number(maxDialutingEmissions));
      const newAPR = (newEmission * klapPrice) / (Number(totalSupply) * price);
      setNewEmissions(newEmission);
      setNewAPR(newAPR);
      return;
    }

    const newVote = (percentage / globalPercentage) * nftFactor;
    const newFactor = (Number(allocPoint) + newVote) / (Number(totalAllocPoint) + nftFactor);

    const newEmission = newFactor * (Number(baseEmissions) + Number(maxDialutingEmissions));
    const newAPR = (newEmission * klapPrice) / (price * Number(totalSupply));

    setNewEmissions(newEmission);
    setNewAPR(newAPR);
  }, [
    globalPercentage,
    totalSupply,
    totalAllocPoint,
    baseEmissions,
    maxDialutingEmissions,
    nftFactor,
    allocPoint,
    klapPrice,
    price,
  ]);

  return (
    <TableItemWrapper onClick={() => {}} className="MobileVoteItem" withGoToTop={true}>
      <TableColumn className="MobileVoteItem__column">
        {sm && <MobileTitle>{intl.formatMessage(messages.type)}</MobileTitle>}
        <div
          style={{ color: isAToken ? '#8855FF' : '#ff85a3', fontWeight: 'bold' }}
          className="MobileVoteItem__token"
        >
          {isAToken ? 'Lend' : 'Borrow'}
        </div>
      </TableColumn>
      <TableColumn className="MobileVoteItem__column">
        {sm && <MobileTitle>{intl.formatMessage(messages.assets)}</MobileTitle>}
        <TokenIcon
          tokenSymbol={currencySymbol}
          height={35}
          width={35}
          tokenFullName={name || asset.name}
          className="MobileVoteItem__token"
        />
      </TableColumn>
      <TableColumn className="MobileVoteItem__column">
        {sm && <MobileTitle>{intl.formatMessage(messages.emissions)}</MobileTitle>}
        <Value
          value={factor * (Number(baseEmissions) + Number(maxDialutingEmissions))}
          compact={true}
          maximumValueDecimals={2}
          withoutSymbol={true}
          tooltipId={`market-size-${asset.symbol}`}
          className="MobileVoteItem__value"
        />
      </TableColumn>
      <TableColumn className="MobileVoteItem__column">
        {sm && <MobileTitle>APR</MobileTitle>}
        <div style={{ display: 'flex' }}>
          <LiquidityMiningCard
            value={(
              (100 *
                (factor * (Number(baseEmissions) + Number(maxDialutingEmissions)) * klapPrice)) /
              (Number(totalSupply) * price)
            ).toLocaleString('en-us', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}
            symbol={currencySymbol}
            type="deposit"
          />
          %
        </div>
      </TableColumn>

      <TableColumn className="MobileVoteItem__column">
        {sm && <MobileTitle>{intl.formatMessage(messages.newEmissions)}</MobileTitle>}
        <Value
          value={newEmissions}
          compact={true}
          maximumValueDecimals={2}
          className="MobileVoteItem__value"
          withoutSymbol={true}
        />
      </TableColumn>

      <TableColumn className="MobileVoteItem__column">
        {sm && <MobileTitle>{intl.formatMessage(messages.newAPR)}</MobileTitle>}
        <div style={{ display: 'flex' }}>
          <LiquidityMiningCard
            value={(newAPR * 100).toLocaleString('en-us', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}
            symbol={currencySymbol}
            type="borrow-variable"
          />
          %
        </div>
      </TableColumn>

      <TableColumn className="MobileVoteItem__column">
        {/*<ReactSlider
          disabled={disabled}
          className="MobileVoteItem__slider"
          thumbClassName="MobileVoteItem__thumb"
          trackClassName="MobileVoteItem__track"
          onChange={(num) => {
            setGlobalPercentage(num);
            setPercentage(num);
          }}
          renderThumb={(props, state) => <div {...props}></div>}
        />*/}
        <div className="MobileVoteItem__percentage">{percentage}%</div>
      </TableColumn>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </TableItemWrapper>
  );
}

import { useState, useEffect, useCallback } from 'react';
import { BigNumber, Contract, ethers } from 'ethers';

import { getNetworkConfig, getProvider } from '../../../helpers/config/markets-and-network-config';
import { StakeData, StakesData } from '../types/stake';
import { Stake } from '@aave/protocol-js';
import { ChainId } from '@aave/contract-helpers';
import Web3 from 'web3';
import MasterChefABI from '../../../contracts/Masterchef.json';
import ERC20ABI from '../../../contracts/ERC20.json';
import MultiRewarderABI from '../../../contracts/MultiRewarder.json';
import { useProtocolDataContext } from '../../protocol-data-provider';
import { formatEther } from 'ethers/lib/utils';
import { HttpProvider } from 'caver-js';
import { getKlapKlayPrice } from '../../../helpers/get-klap-price';

function formatRawStakeData(
  claimableReward: string,
  klayClaimableReward: string,
  totalEmissionsPerDay: string,
  underlyingTokenUserBalance: string,
  stakeTokenTotalSupply: string,
  rewardsPerSecond: string,
  isAllowed: boolean,
  amountStaked: string,
  lockedTokenTotalSupply: string,
  rewardTokenPriceUsd: number,
  dialutedApr: string,
  baseApr: string,
  klayApr: string,
  klayPrice: number,
  lpPrice: number
): StakeData {
  return {
    stakeTokenTotalSupply: stakeTokenTotalSupply,
    totalEmissionPerDay: totalEmissionsPerDay,
    distributionPerSecond: rewardsPerSecond,
    stakeTokenUserBalance: amountStaked,
    lockedTokenUserBalance: lockedTokenTotalSupply,
    underlyingTokenUserBalance: underlyingTokenUserBalance,
    userIncentivesToClaim: claimableReward,
    klayClaimableReward: klayClaimableReward,
    rewardTokenPriceUsd: '' + rewardTokenPriceUsd,
    isAllowed: isAllowed,
    dialutedApr: dialutedApr,
    baseApr: baseApr,
    klayApr,
    klayPrice: klayPrice,
    lpPrice: lpPrice,
  };
}

export function useStakeDataWithRpc(
  stakeDataProvider: string,
  chainId: ChainId,
  user?: string,
  skip: boolean = false,
  poolingInterval: number = 30
) {
  const [loading, setLoading] = useState(true);
  const [stakeData, setStakeData] = useState<StakesData>();
  const [usdPriceEth, setUsdPriceEth] = useState<string>('0');
  const { currentMarketData } = useProtocolDataContext();

  const loadStakeData = async (_userAddress: string | undefined) => {
    const masterchef = new Contract(
      currentMarketData.addresses.MASTERCHEF!,
      MasterChefABI,      // @ts-ignore

      getProvider(chainId)
    );
    const erc20 = new Contract(
      currentMarketData.addresses.LP_TOKEN!,
      ERC20ABI,      // @ts-ignore

      getProvider(chainId)
    );
    const multirewarder = new Contract(
      currentMarketData.addresses.MULTIREWARDER!,
      MultiRewarderABI,      // @ts-ignore

      getProvider(chainId)
    );
    const userAddress = _userAddress;

    if (userAddress === ethers.constants.AddressZero || !userAddress) {
      return;
    }

    try {
      const [
        claimableReward,
        claimableKlayReward,
        poolInfo,
        multirewarderTokenPerBlock,
        dialutingRepartition,
        underlyingTokenUserBalance,
        stakeTokenTotalSupply,
        rewardsPerSecond,
        allowance,
        userInfo,
        { klap, klay, lp },
      ] = await Promise.all([
        masterchef.claimableReward(userAddress, [currentMarketData.addresses.LP_TOKEN!]),
        multirewarder.pendingTokens(currentMarketData.addresses.LP_TOKEN!, userAddress),
        masterchef.poolInfo(currentMarketData.addresses.LP_TOKEN!),
        multirewarder.retrieveTokenPerBlock(currentMarketData.addresses.LP_TOKEN!, 0),
        masterchef.dialutingRepartition(),
        erc20.balanceOf(user),
        erc20.balanceOf(currentMarketData.addresses.MASTERCHEF!),
        masterchef.rewardsPerSecond(),
        erc20.allowance(user, currentMarketData.addresses.MASTERCHEF!),
        masterchef.userInfo(currentMarketData.addresses.LP_TOKEN!, userAddress),
        getKlapKlayPrice(currentMarketData.addresses.LP_TOKEN!),
      ]);
      const klapPrice = klap;
      const klayPrice = klay;
      const lpPrice = lp;
      //console.log('allowance', allowance);
      const isAllowed = Number(allowance) > 0;
      console.log('rewards per second pool 2', rewardsPerSecond);
      console.log(
        'rewards pers econd per day',
        formatEther(rewardsPerSecond.mul(BigNumber.from(86400)))
      );
      console.log('claimablereward', formatEther(claimableReward[0]));
      const emissionsPerYear = rewardsPerSecond.mul(BigNumber.from(86400 * 365));
      const klayEmissionsPerYear = multirewarderTokenPerBlock.mul(BigNumber.from(86400 * 365));
      console.log('here', dialutingRepartition);
      const baseAmountNondialuting = emissionsPerYear
        .mul(BigNumber.from(1000).sub(Number(dialutingRepartition)))
        .mul(BigNumber.from((klapPrice * 10000).toFixed(0)))
        .div(BigNumber.from(10000 * 1000));

      console.log('here2');
      const baseAmountDialuting = emissionsPerYear
        .mul(BigNumber.from(dialutingRepartition))
        .mul(BigNumber.from((klapPrice * 10000).toFixed(0)))
        .div(BigNumber.from(10000 * 1000));
      console.log('baseamount', formatEther(baseAmountNondialuting));
      console.log('baseamountdialuting', formatEther(baseAmountDialuting));
      console.log('staketokenstoal', formatEther(stakeTokenTotalSupply));
      console.log(
        'nondialuting',
        (Number(formatEther(baseAmountNondialuting.mul(10000))) / 10000) *
          (Number(formatEther(stakeTokenTotalSupply)) === 0
            ? 1
            : Number(formatEther(stakeTokenTotalSupply))) *
          lpPrice
      );
      console.log('userinfo factor', userInfo);
      console.log('user info amount', formatEther(userInfo.amount));
      console.log('poolinfo', poolInfo);
      const claimableKlay = claimableKlayReward[1].length === 0 ? 0 : claimableKlayReward[1][0];
      setStakeData({
        [Stake.bpt]: formatRawStakeData(
          formatEther(claimableReward[0]),
          formatEther(claimableKlay),
          formatEther(rewardsPerSecond.mul(BigNumber.from(86400))),
          formatEther(underlyingTokenUserBalance),
          formatEther(stakeTokenTotalSupply),
          BigNumber.from(rewardsPerSecond).toString(),
          isAllowed,
          formatEther(userInfo.amount),
          formatEther(userInfo.ve_deposited_amount),
          klapPrice,
          Number(formatEther(userInfo.factor)) === 0 || Number(formatEther(userInfo.amount)) === 0
            ? '0'
            : (
                (Number(formatEther(baseAmountDialuting)) *
                  (BigNumber.from(userInfo.factor)
                    .mul(1000000)
                    .div(poolInfo.sumOfFactors)
                    .toNumber() /
                    1000000)) /
                (Number(formatEther(userInfo.amount)) * lpPrice)
              ).toString(),
          '' +
            Number(formatEther(baseAmountNondialuting)) /
              (Number(formatEther(stakeTokenTotalSupply)) === 0
                ? 1
                : Number(formatEther(stakeTokenTotalSupply)) * lpPrice),
          '' +
            (Number(formatEther(klayEmissionsPerYear)) * klayPrice) /
              (Number(formatEther(stakeTokenTotalSupply)) === 0
                ? 1
                : Number(formatEther(stakeTokenTotalSupply)) * lpPrice),
          Number(klayPrice),
          lpPrice
        ),
      });
      setUsdPriceEth(/*data[2].toString()*/ '1');
    } catch (e) {
      console.log('Stake data loading error', e);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);

    if (!skip) {
      loadStakeData(user);
      const intervalId = setInterval(() => loadStakeData(user), poolingInterval * 1000);
      return () => clearInterval(intervalId);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, skip, poolingInterval, stakeDataProvider, chainId]);

  return {
    loading,
    data: stakeData,
    usdPriceEth,
    refresh: async () => await loadStakeData(user),
  };
}

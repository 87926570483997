import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import TableItemWrapper from '../../../../components/BasicTable/TableItemWrapper';
import TableColumn from '../../../../components/BasicTable/TableColumn';
import Value from '../../../../components/basic/Value';
import FreezedWarning from '../../../../components/FreezedWarning';
import NoData from '../../../../components/basic/NoData';
import { getAssetInfo, TokenIcon } from '../../../../helpers/config/assets-config';

import staticStyles from './style';
import Preloader from '../../../../components/basic/Preloader';
import DefaultButton from '../../../../components/basic/DefaultButton';
import LiquidityMiningBreakdown from '../../../../components/liquidityMining/LiquidityMiningBreakdown';
import messages from './messages';
import { useIntl } from 'react-intl';
import { useServices } from '../../screens/Markets/functions';

export interface MarketTableItemProps {
  id: string;
  underlyingAsset: string;
  currencySymbol: string;
  totalLiquidity: number;
  totalLiquidityInUSD: number;
  totalBorrows: number;
  totalBorrowsInUSD: number;
  depositAPY: number;
  aincentivesAPR?: string;
  vincentivesAPR?: string;
  sincentivesAPR?: string;
  avg30DaysLiquidityRate: number;
  stableBorrowRate: number;
  variableBorrowRate: number;
  avg30DaysVariableRate: number;
  borrowingEnabled?: boolean;
  stableBorrowRateEnabled?: boolean;
  isFreezed?: boolean;
  isPriceInUSD?: boolean;
  canVest?: boolean;
  claimTokens?: string[];
  depositFarmAPY?: number;
  depositVeFarmAPY?: number;
  borrowFarmAPY?: number;
  borrowVeFarmAPY?: number;
  depositKlayAPY?: number;
  name?: string;
  borrowKlayAPY?: number;
  vestedAmount?: string;
  vestedAmountKlay?: string;
}

export default function MarketTableItem({
  id,
  underlyingAsset,
  currencySymbol,
  totalLiquidity,
  totalLiquidityInUSD,
  totalBorrows,
  totalBorrowsInUSD,
  depositAPY,
  aincentivesAPR,
  vincentivesAPR,
  sincentivesAPR,
  avg30DaysLiquidityRate,
  stableBorrowRate,
  variableBorrowRate,
  avg30DaysVariableRate,
  borrowingEnabled,
  stableBorrowRateEnabled,
  isFreezed,
  isPriceInUSD,
  canVest,
  claimTokens,
  depositFarmAPY,
  depositVeFarmAPY,
  borrowFarmAPY,
  borrowVeFarmAPY,
  depositKlayAPY,
  borrowKlayAPY,
  name,
  vestedAmount,
  vestedAmountKlay,
}: MarketTableItemProps) {
  const intl = useIntl();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const { vest } = useServices();
  const asset = getAssetInfo(currencySymbol);

  const handleClick = () => {
    history.push(`/reserve-overview/${underlyingAsset}-${id}`);
  };

  return (
    <TableItemWrapper onClick={handleClick} className="MarketTableItem" withGoToTop={true}>
      <TableColumn className="MarketTableItem__column">
        <TokenIcon
          tokenSymbol={currencySymbol}
          height={35}
          width={35}
          tokenFullName={name || asset.name}
          className="MarketTableItem__token"
        />
      </TableColumn>
      <TableColumn className="MarketTableItem__column">
        <Value
          value={isPriceInUSD ? totalLiquidityInUSD : totalLiquidity}
          compact={true}
          maximumValueDecimals={2}
          withoutSymbol={true}
          tooltipId={`market-size-${asset.symbol}`}
          symbol={isPriceInUSD ? 'USD' : ''}
          tokenIcon={isPriceInUSD}
          className="MarketTableItem__value"
        />
      </TableColumn>
      <TableColumn className="MarketTableItem__column">
        {borrowingEnabled ? (
          <Value
            value={isPriceInUSD ? totalBorrowsInUSD : totalBorrows}
            compact={true}
            maximumValueDecimals={2}
            className="MarketTableItem__value"
            withoutSymbol={true}
            symbol={isPriceInUSD ? 'USD' : ''}
            tokenIcon={isPriceInUSD}
            tooltipId={`borrows-size-${asset.symbol}`}
          />
        ) : (
          <NoData color="dark" />
        )}
      </TableColumn>

      {!isFreezed && (
        <>
          <TableColumn className="MarketTableItem__column">
            <LiquidityMiningBreakdown
              currencySymbol={currencySymbol}
              baseValue={depositAPY}
              klayValue={depositKlayAPY || 0}
              farmValue={depositFarmAPY || 0}
              boostValue={depositVeFarmAPY || 0}
              symbol={currencySymbol}
              type={'lend'}
              index={id}
            />
          </TableColumn>

          <TableColumn className="MarketTableItem__column">
            <LiquidityMiningBreakdown
              currencySymbol={currencySymbol}
              baseValue={variableBorrowRate}
              klayValue={borrowKlayAPY || 0}
              farmValue={borrowFarmAPY || 0}
              boostValue={borrowVeFarmAPY || 0}
              symbol={currencySymbol}
              type={'borrow'}
              index={id}
            />
          </TableColumn>
        </>
      )}

      <TableColumn className="MarketTableItem__column">
        {!isLoading ? (
          <DefaultButton
            title={`${intl.formatMessage(messages.vest)} ${
              Number(vestedAmount) <= 0.01
                ? ''
                : Number(vestedAmount).toLocaleString('en-us', {
                    maximumFractionDigits: 2,
                  })
            } KLAP${
              Number(vestedAmountKlay) <= 0.01
                ? ''
                : ` & ${Number(vestedAmountKlay).toLocaleString('en-us', {
                    maximumFractionDigits: 2,
                  })} ${currencySymbol === 'KSD' ? 'sKOKOA-FUT' : 'KLAY-FUT'}`
            }`}
            color="blue"
            transparent={true}
            size="small"
            className="VestButton"
            onClick={async (e) => {
              e.stopPropagation();
              setIsLoading(true);
              await vest(claimTokens!);
              setIsLoading(false);
            }}
          />
        ) : canVest && isLoading ? (
          <Preloader smallSize />
        ) : (
          <NoData color="dark" />
        )}
      </TableColumn>

      {isFreezed && (
        <>
          <div />
          <div className="MarketTableItem__isFreezed-inner">
            <FreezedWarning symbol={currencySymbol} />
          </div>
        </>
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </TableItemWrapper>
  );
}

import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .MobileVoteItem {
    height: 255px;
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: default !important;
    &:hover {
      box-shadow: 0px 4px 24px rgba(88, 102, 126, 0.08), 0px 1px 2px rgba(88, 102, 126, 0.12) !important;
    }

    &:active,
    &:hover {
      border-color: transparent !important;
    }

    &__column {
      width: 100%;
      display: flex;
      justify-content: space-between !important;
      flex-direction: row !important;
      &:first-child {
        height: 75px;
      }
    }

    .MobileVoteItem__token {
      .TokenIcon__name {
        min-width: 140px;
      }
    }

    .MobileVoteItem__value {
      &:first-of-type {
        margin-bottom: 4px;
      }
      .Value__value {
        @include respond-to(sm) {
          font-size: $regular;
        }
      }
    }
    .MobileVoteItem__percent {
      .ValuePercent__value {
        @include respond-to(sm) {
          font-size: $regular;
        }
      }
    }

    &__isFreezed-inner {
      position: relative;
      flex: 3;
      text-align: center;
    }

    .TokenIcon__dollar {
      @include respond-to(sm) {
        font-size: $small;
      }
    }

    .MobileVoteItem__button {
      width: 95px;
      min-height: 32px;
      font-size: $small;
      @include respond-to(xl) {
        width: 70px;
        min-height: 26px;
      }
      @include respond-to(lg) {
        width: 50px;
        font-size: $extraSmall;
      }
      @include respond-to(md) {
        width: 70px;
        font-size: $small;
      }
    }

    .MobileVoteItem__percentage {
      background-color: #000;
      color: #fff;
      padding: 5px;
    }

    .MobileVoteItem__slider {
      height: 26px;
      width: 100%;
      overflow: hidden;

      transition: 0.2s;

      .MobileVoteItem__thumb:focus-visible {
        outline: none;
      }

      .MobileVoteItem__thumb:active {
        background-color: #113188;
      }

      .MobileVoteItem__thumb {
        height: 26px;
        width: 26px;
        border-radius: 50%;
        background-color: #1450f5;
      }

      .MobileVoteItem__track {
        margin-top: 13px;
        height: 2px;
        width: 100%;
        background-color: #00cc88;
      }
    }
  }
`;

export default staticStyles;

import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { valueToBigNumber, BigNumber } from '@aave/protocol-js';

import { useDynamicPoolDataContext } from '../../libs/pool-data-provider';
import toggleLocalStorageClick from '../../helpers/toggle-local-storage-click';
import TopPanelWrapper from '../wrappers/TopPanelWrapper';
import Row from '../basic/Row';
import Value from '../basic/Value';
import MaxLTVHelpModal from '../HelpModal/MaxLTVHelpModal';
import ValuePercent from '../basic/ValuePercent';
import HealthFactor from '../HealthFactor';
import DefaultButton from '../basic/DefaultButton';
import NoData from '../basic/NoData';
import CircleCompositionBar, {
  CircleCompositionBarItem,
} from '../compositionBars/CircleCompositionBar';
import CircleCollateralCompositionBar from '../compositionBars/CircleCollateralCompositionBar';
import LTVInfoModal from '../LTVInfoModal';
import ApproximateBalanceHelpModal from '../HelpModal/ApproximateBalanceHelpModal';

import messages from './messages';
import staticStyles from './style';
import { getAssetInfo, getAssetColor } from '../../helpers/config/assets-config';

export default function DepositBorrowTopPanel() {
  const intl = useIntl();
  const { user, reserves } = useDynamicPoolDataContext();

  const [isCollapse, setIsCollapse] = useState(
    localStorage.getItem('borrowDepositTopPanelIsCollapse') === 'true'
  );
  const [isLTVModalVisible, setLTVModalVisible] = useState(false);

  const maxBorrowAmount = valueToBigNumber(user?.totalBorrowsMarketReferenceCurrency || '0').plus(
    user?.availableBorrowsMarketReferenceCurrency || '0'
  );
  const collateralUsagePercent = maxBorrowAmount.eq(0)
    ? '1'
    : valueToBigNumber(user?.totalBorrowsMarketReferenceCurrency || '0')
        .div(maxBorrowAmount)
        .toFixed();

  const loanToValue =
    user?.totalCollateralMarketReferenceCurrency === '0'
      ? '0'
      : valueToBigNumber(user?.totalBorrowsMarketReferenceCurrency || '0')
          .dividedBy(user?.totalCollateralMarketReferenceCurrency || '1')
          .toFixed();

  const depositCompositionData: CircleCompositionBarItem[] = [];
  const borrowCompositionData: CircleCompositionBarItem[] = [];

  user?.userReservesData.forEach((userReserve) => {
    const poolReserve = reserves.find((res) => res.name === userReserve.reserve.name);

    if (!poolReserve) {
      throw new Error('data is inconsistent pool reserve is not available');
    }
    if (userReserve.underlyingBalance !== '0' || userReserve.totalBorrows !== '0') {
      if (userReserve.underlyingBalance !== '0') {
        depositCompositionData.push({
          label: `${getAssetInfo(userReserve.reserve.symbol).formattedName}  ${intl.formatNumber(
            valueToBigNumber(userReserve.underlyingBalanceMarketReferenceCurrency)
              .dividedBy(user?.totalLiquidityMarketReferenceCurrency)
              .multipliedBy(100)
              .toNumber(),
            { maximumFractionDigits: 2 }
          )}%`,
          value: Number(userReserve.underlyingBalanceMarketReferenceCurrency),
          color: getAssetColor(userReserve.reserve.symbol),
        });
      }
      if (userReserve.totalBorrows !== '0') {
        borrowCompositionData.push({
          label: `${getAssetInfo(userReserve.reserve.symbol).formattedName}  ${intl.formatNumber(
            valueToBigNumber(userReserve.totalBorrowsMarketReferenceCurrency)
              .dividedBy(maxBorrowAmount)
              .multipliedBy(100)
              .toNumber(),
            { maximumFractionDigits: 2 }
          )}%`,
          value: Number(userReserve.totalBorrowsMarketReferenceCurrency),
          color: getAssetColor(userReserve.reserve.symbol),
        });

        const availableBorrowPower = borrowCompositionData
          .reduce((acc, slice) => acc.minus(slice.value), maxBorrowAmount)
          .toNumber();
        const usedBorrowPower = borrowCompositionData
          .reduce((acc, slice) => acc.plus(slice.value), new BigNumber(0))
          .toNumber();

        borrowCompositionData.push({
          value: availableBorrowPower,
          label: `${intl.formatMessage(messages.borrowingPowerAvailable)}: ${intl.formatNumber(
            new BigNumber(1)
              .minus(valueToBigNumber(usedBorrowPower).dividedBy(maxBorrowAmount))
              .multipliedBy(100)
              .toNumber(),
            {
              maximumFractionDigits: 2,
            }
          )}%`,
          color: '#000',
        });
      }
    }
  });

  return (
    <div className="DepositBorrowTopPanel">
      {user ? (
        <TopPanelWrapper
          className={classNames('DepositBorrowTopPanel__topPanel', {
            DepositBorrowTopPanel__topPanelTransparent: user,
          })}
          isCollapse={isCollapse}
          setIsCollapse={() =>
            toggleLocalStorageClick(isCollapse, setIsCollapse, 'borrowDepositTopPanelIsCollapse')
          }
          withoutCollapseButton={true}
        >
          <div className="DepositBorrowTopPanel__topPanel-captionWrapper">
            <div className="DepositBorrowTopPanel__topPanel-caption">
              <>
                <p
                  className={classNames({
                    DepositBorrowTopPanel__topPanelCaptionFull:
                      !depositCompositionData.length && !borrowCompositionData.length,
                  })}
                >
                  <i className="DepositBorrowTopPanel__title">
                    {intl.formatMessage(
                      !depositCompositionData.length
                        ? messages.noDeposits
                        : messages.depositInformation
                    )}
                  </i>
                </p>
                {!!depositCompositionData.length && (
                  <p>
                    <i className="DepositBorrowTopPanel__title">
                      {intl.formatMessage(messages.borrowInformation)}
                    </i>
                  </p>
                )}
              </>
            </div>
          </div>

          <div
            className={classNames('DepositBorrowTopPanel__topPanel-info', {
              DepositBorrowTopPanel__topPanelInfoCollapse: isCollapse,
              DepositBorrowTopPanel__topPanelNoUser: !user,
            })}
          >
            {user && (
              <>
                <div
                  className={classNames('DepositBorrowTopPanel__topPanel-inner', {
                    DepositBorrowTopPanel__topPanelInnerFull: !depositCompositionData.length,
                  })}
                >
                  <div className="DepositBorrowTopPanel__topPanel-values">
                    <Row
                      title={
                        <ApproximateBalanceHelpModal
                          text={intl.formatMessage(messages.approximateBalance)}
                          color="white"
                          lightWeight={true}
                        />
                      }
                      color="white"
                      weight="light"
                      isColumn={true}
                    >
                      {user && user.totalLiquidityUSD !== '0' ? (
                        <Value
                          value={user.totalLiquidityUSD}
                          symbol="USD"
                          tokenIcon={true}
                          withSmallDecimals={true}
                          color="white"
                        />
                      ) : (
                        <NoData />
                      )}
                    </Row>
                  </div>

                  {!isCollapse && !!depositCompositionData.length && (
                    <div className="DepositBorrowTopPanel__topPanel-bars">
                      <CircleCompositionBar
                        totalValue={Number(user?.totalLiquidityMarketReferenceCurrency || 0)}
                        data={depositCompositionData}
                      />
                      <div>{intl.formatMessage(messages.depositComposition)}</div>
                    </div>
                  )}
                </div>

                {!!depositCompositionData.length && (
                  <div
                    className="DepositBorrowTopPanel__topPanel-inner"
                    style={{ border: '1px solid #FF3366' }}
                  >
                    <div
                      className={classNames('DepositBorrowTopPanel__topPanel-values', {
                        DepositBorrowTopPanel__topPanelValuesCollapse: isCollapse,
                      })}
                    >
                      <div
                        className={classNames('DepositBorrowTopPanel__topPanel-valuesInner', {
                          DepositBorrowTopPanel__topPanelValuesInnerCollapse: isCollapse,
                        })}
                      >
                        <Row
                          title={intl.formatMessage(messages.youBorrowed)}
                          color="white"
                          weight="light"
                          isColumn={true}
                        >
                          {user && user.totalBorrowsUSD !== '0' ? (
                            <Value
                              value={user.totalBorrowsUSD}
                              symbol="USD"
                              tokenIcon={true}
                              minimumValueDecimals={2}
                              maximumValueDecimals={2}
                              color="white"
                            />
                          ) : (
                            <NoData />
                          )}
                        </Row>

                        {isCollapse && (
                          <Row
                            title={intl.formatMessage(messages.yourCollateral)}
                            color="white"
                            weight="light"
                            isColumn={true}
                          >
                            {user && user.totalCollateralUSD !== '0' ? (
                              <Value
                                value={user.totalCollateralUSD}
                                symbol="USD"
                                tokenIcon={true}
                                minimumValueDecimals={2}
                                maximumValueDecimals={2}
                                color="white"
                              />
                            ) : (
                              <NoData />
                            )}
                          </Row>
                        )}

                        <HealthFactor
                          value={user?.healthFactor || '-1'}
                          isColumn={true}
                          titleColor="white"
                          titleLightWeight={true}
                          withHALLink={true}
                        />
                      </div>

                      <div className={classNames('DepositBorrowTopPanel__topPanel-valuesInner')}>
                        {!isCollapse && (
                          <Row
                            title={intl.formatMessage(messages.yourCollateral)}
                            color="white"
                            weight="light"
                            isColumn={true}
                          >
                            {user && user.totalCollateralUSD !== '0' ? (
                              <Value
                                value={user.totalCollateralUSD}
                                symbol="USD"
                                tokenIcon={true}
                                minimumValueDecimals={2}
                                maximumValueDecimals={2}
                                color="white"
                              />
                            ) : (
                              <NoData />
                            )}
                          </Row>
                        )}

                        {!isCollapse && (
                          <Row
                            title={intl.formatMessage(messages.borrowingPowerUsed)}
                            color="white"
                            weight="light"
                            isColumn={true}
                          >
                            {user && collateralUsagePercent !== '0' ? (
                              <ValuePercent value={collateralUsagePercent} color="white" />
                            ) : (
                              <NoData />
                            )}
                          </Row>
                        )}
                      </div>

                      <div
                        className={classNames('DepositBorrowTopPanel__topPanel-valuesInner', {
                          DepositBorrowTopPanel__topPanelValuesInnerCollapse: isCollapse,
                        })}
                      >
                        {!isCollapse && (
                          <Row
                            title={
                              <MaxLTVHelpModal
                                text={intl.formatMessage(messages.currentLTV)}
                                color="white"
                                lightWeight={true}
                              />
                            }
                            color="white"
                            weight="light"
                            isColumn={true}
                          >
                            {user && loanToValue !== '0' ? (
                              <ValuePercent value={loanToValue} color="white" />
                            ) : (
                              <NoData />
                            )}
                          </Row>
                        )}

                        {loanToValue !== '0' && (
                          <DefaultButton
                            title={intl.formatMessage(messages.details)}
                            color="white"
                            transparent={true}
                            className={classNames('DepositBorrowTopPanel__button', {
                              DepositBorrowTopPanel__buttonCollapse: isCollapse,
                            })}
                            size="small"
                            onClick={() => setLTVModalVisible(true)}
                          />
                        )}
                      </div>
                    </div>

                    {!isCollapse && (
                      <div className="DepositBorrowTopPanel__topPanel-bars DepositBorrowTopPanel__topPanel-col">
                        <div
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          {!!borrowCompositionData.length && (
                            <CircleCompositionBar
                              totalValue={Number(maxBorrowAmount || 0)}
                              data={borrowCompositionData}
                            />
                          )}
                          <div>{intl.formatMessage(messages.borrowComposition)}</div>
                        </div>

                        {+collateralUsagePercent !== 1 && <CircleCollateralCompositionBar />}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </TopPanelWrapper>
      ) : (
        <></>
      )}

      {loanToValue !== '0' && (
        <LTVInfoModal visible={isLTVModalVisible} setVisible={setLTVModalVisible} />
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .DepositBorrowTopPanel {
          &__topPanel-caption {
            p {
              background: #fff;
            }
          }

          &__topPanel-inner {
            background: #fff;
          }

          &__topPanel-captionWrapper {
            color: ${'#000'};
          }
        }
      `}</style>
    </div>
  );
}

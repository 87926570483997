import { useState } from 'react';
import { useConnectionStatusContext } from '../../../../libs/connection-status-provider';
import { usePolling } from '../../../../libs/hooks/use-polling';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import ERC20ABI from '../../../../contracts/ERC20.json';
import { formatEther } from 'ethers/lib/utils';
import { useMultiCall } from '../../../../libs/pool-data-provider/hooks/use-multicall';
import { useStaticPoolDataContext } from '../../../../libs/pool-data-provider';
import { getKlapKlayPrice } from '../../../../helpers/get-klap-price';
import RewardClaimerABI from '../../../../contracts/RewardClaimer.json';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers';
const POLLING_INTERVAL = 120 * 1000;

export enum SKOKOA_BUTTON_STATE {
  DONT_SHOW,
  SHOW_APPROVE,
  SHOW_REDEEM,
}

interface InfoData {
  klapLocked: number;
  klapStaked: number;
  klapPrice: number;
  totalSupply: number;
  klapBalance: number;

  preminingKlapRewards: number;
  preminingKlayRewards: number;
  preminingKlapClaimable: boolean;

  showKlayFutsButton: boolean;
  klayFutsBalance: BigNumber;
  skokoaFutsBalance: BigNumber;
  skokoaFutState: SKOKOA_BUTTON_STATE;
}

interface ReturnData {
  loading: boolean;
  error: any;
  data: InfoData;
  refresh: () => void;
}

export const useGetMarketInfo: () => ReturnData = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<InfoData>({
    klapLocked: 0,
    klapStaked: 0,
    klapPrice: 0,
    totalSupply: 0,
    klapBalance: 0,
    preminingKlapRewards: 0,
    preminingKlayRewards: 0,
    preminingKlapClaimable: false,
    showKlayFutsButton: false,
    klayFutsBalance: BigNumber.from(0),
    skokoaFutsBalance: BigNumber.from(0),
    skokoaFutState: SKOKOA_BUTTON_STATE.DONT_SHOW,
  });
  const { userId } = useStaticPoolDataContext();
  const { isRPCActive } = useConnectionStatusContext();
  const erc20_call = useMultiCall([ERC20ABI, ERC20ABI, ERC20ABI]);
  const rewardclaimer_call = useMultiCall([RewardClaimerABI]);
  const { currentMarketData } = useProtocolDataContext();
  const { library: provider } = useWeb3React();

  const fetchData = async () => {
    try {
      setLoading(true);

      const [[[veLockedAmount, totalSupply], [lpStaked]], { klap }] = await Promise.all([
        erc20_call(
          [currentMarketData.addresses.MAIN_TOKEN!, currentMarketData.addresses.LP_TOKEN!],
          [['balanceOf', 'totalSupply'], ['balanceOf']],
          [[[currentMarketData.addresses.VE!], []], [[currentMarketData.addresses.MASTERCHEF!]]]
        ),
        getKlapKlayPrice(currentMarketData.addresses.LP_TOKEN!),
      ]);
      let balance = 0;
      let preminingKlayRewards = 0;
      let preminingKlapRewards = 0;
      let preminingKlapClaimable = false;
      let klayFutsBalance = BigNumber.from('0');
      let balanceOfKlayFut = BigNumber.from('0');
      let balanceOfSkokoaFut = BigNumber.from('0');
      let skokoaAllowance = BigNumber.from('0');
      if (userId) {
        const [
          _balanceOfKlayFut,
          [[balanceUser], [klayFutBalanceUser], [skokoaFutBalanceUser, skokoaFutAllowance]],
          [[klayToClaim, klapToClaim, isPreminingKlapClaimable]],
        ] = await Promise.all([
          provider!.getBalance(currentMarketData.addresses.KLAY_FUT),
          erc20_call(
            [
              currentMarketData.addresses.MAIN_TOKEN!,
              currentMarketData.addresses.KLAY_FUT!,
              currentMarketData.addresses.SKOKOA_FUT!,
            ],
            [['balanceOf'], ['balanceOf'], ['balanceOf', 'allowance']],
            [
              [[userId]],
              [[userId]],
              [[userId], [userId, currentMarketData.addresses.SKOKOA_FUT_REDEEMER!]],
            ]
          ),
          rewardclaimer_call(
            [currentMarketData.addresses.PRE_MINING_REWARD_CLAIMER!],
            [['klayToClaim', 'klapToClaim', 'klapClaimable']],
            [[[userId], [userId], []]]
          ),
        ]);
        balanceOfKlayFut = _balanceOfKlayFut;
        preminingKlapClaimable = isPreminingKlapClaimable[0];
        balance = Number(formatEther(balanceUser[0]));
        klayFutsBalance = BigNumber.from(klayFutBalanceUser[0]);
        preminingKlayRewards = Number(formatEther(klayToClaim[0]));
        preminingKlapRewards = Number(formatEther(klapToClaim[0]));
        balanceOfSkokoaFut = BigNumber.from(skokoaFutBalanceUser[0]);
        skokoaAllowance = BigNumber.from(skokoaFutAllowance[0]);
        console.log('BALANCE SKOKOA: ', balanceOfSkokoaFut);
        console.log('BALANCE ALLOWANCE: ', skokoaAllowance);
      }

      const data: InfoData = {
        klapLocked: Number(formatEther(veLockedAmount[0])),
        klapStaked: Number(formatEther(lpStaked[0])),
        klapPrice: klap,
        totalSupply: Number(formatEther(totalSupply[0])),
        klapBalance: balance,
        preminingKlapRewards: preminingKlapRewards,
        preminingKlayRewards: preminingKlayRewards,
        preminingKlapClaimable: preminingKlapClaimable,
        showKlayFutsButton: BigNumber.from(balanceOfKlayFut).gt(0) && Number(klayFutsBalance) > 0,
        klayFutsBalance: klayFutsBalance,
        skokoaFutsBalance: balanceOfSkokoaFut,
        skokoaFutState:
          BigNumber.from(balanceOfSkokoaFut).gt(0) && BigNumber.from(skokoaAllowance).gt(0)
            ? SKOKOA_BUTTON_STATE.SHOW_REDEEM
            : BigNumber.from(balanceOfSkokoaFut).gt(0)
            ? SKOKOA_BUTTON_STATE.SHOW_APPROVE
            : SKOKOA_BUTTON_STATE.DONT_SHOW,
      };
      setData(data);
      setError(false);
    } catch (e) {
      console.log('e', e);
      setError(e.message);
    }
    setLoading(false);
  };

  usePolling(fetchData, POLLING_INTERVAL, !isRPCActive, []);

  return {
    loading,
    error,
    data: data,
    refresh: fetchData,
  };
};

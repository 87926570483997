import React, { useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { DropdownWrapper, rgba, textCenterEllipsis, useThemeContext } from '@aave/aave-ui-kit';

import { useUserWalletDataContext } from '../../../libs/web3-data-provider';
import ConnectButton, { SwitchNetwork } from '../../ConnectButton';

import staticStyles from './style';
import { getNetworkConfig } from '../../../helpers/config/markets-and-network-config';
import useGetEnsName from '../../../libs/hooks/use-get-ens-name';
import AccountButton from '../../AccountButton';
import PriceButton from '../../PriceButton';
import messages from './messages';
import { useIntl } from 'react-intl';
import { useMenuContext } from '../../../libs/menu';

export default function AddressInfo() {
  const intl = useIntl();
  const { currentAccount, disconnectWallet } = useUserWalletDataContext();
  const { closeMobileMenu } = useMenuContext();
  const { currentTheme } = useThemeContext();
  const { chainId } = useWeb3React();
  const { ensName } = useGetEnsName(currentAccount);
  const [visible, setVisible] = useState(false);
  const ensNameAbbreviated = ensName
    ? ensName.length > 18
      ? textCenterEllipsis(ensName, 12, 3)
      : ensName
    : undefined;
  const config = chainId ? getNetworkConfig(chainId) : undefined;
  let networkColor = '';
  if (config?.isFork) {
    networkColor = '#ff4a8d';
  } else if (config?.isTestnet) {
    networkColor = '#7157ff';
  } else {
    networkColor = '#65c970';
  }
  const borderColor = rgba(`${currentTheme.darkBlue.rgb}, 0.1`);
  const hoverColor = rgba(`${currentTheme.darkBlue.rgb}, 0.05`);
  return (
    <div className="AddressInfo">
      <PriceButton />
      {chainId !== 8217 && <SwitchNetwork />}
      {currentAccount ? (
        <DropdownWrapper
          visible={visible}
          setVisible={setVisible}
          horizontalPosition="right"
          verticalPosition="bottom"
          className="AddressInfo__dropdownWrapper"
          buttonComponent={
            <AccountButton
              onClick={() => setVisible(!visible)}
              name={
                ensNameAbbreviated ? ensNameAbbreviated : textCenterEllipsis(currentAccount, 4, 4)
              }
            />
          }
        >
          <div className="AddressInfo__content">
            <button
              className="AddressInfo__contentButton"
              type="button"
              onClick={() => {
                disconnectWallet();
                closeMobileMenu();
              }}
            >
              <span>{intl.formatMessage(messages.disconnect)}</span>
            </button>
          </div>
        </DropdownWrapper>
      ) : (
        <ConnectButton size="small" />
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .PriceButton__tooltip {
          background-color: #1450f5 !important;
          border-radius: 16px;
        }
        .AddressInfo {
          &__button {
            color: ${'#000'};
            &:hover {
              border-color: ${'#000'};
            }
          }

          &__buttonActive {
            border-color: ${'#000'};
          }

          &__content {
            color: ${currentTheme.darkBlue.hex};
          }

          &__content-caption {
            border-bottom: 1px solid ${currentTheme.darkBlue.hex};
          }
          &__content-network {
            i {
              background: ${networkColor};
            }
          }

          &__contentButton {
            color: ${currentTheme.darkBlue.hex} !important;
            border-bottom: 1px solid ${borderColor};
            &:hover {
              background: ${hoverColor};
            }
          }
        }
      `}</style>
    </div>
  );
}

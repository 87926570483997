import React, { useState } from 'react';
import { RowContainer, Subtitle, Title, Row } from '../../manage/components/styles';
import moment from 'moment';
import styled from 'styled-components';
import DefaultButton from '../../../components/basic/DefaultButton';
import Preloader from '../../../components/basic/Preloader';
import _ from 'lodash';
import { useGetManageInfo } from '../../manage/hooks';
import { useIntl } from 'react-intl';
import messages from '../../manage/messages';
import messagesLocal from '../messages';

interface YourVeInfoProps {
  listTokens: {
    tokenId: string;
    isKlap: boolean;
    amount: number;
    expiry: number;
    earnedKlayKlapLP?: number;
    earnedKlap?: number;
  }[];
  openModal: (tokenId: string, amount: string, expiry: string) => void;
  onClaim: (tokenId: string) => void;
  onClaimFromMasterchef: () => void;
}

const VeInfoRow = styled(Row)`
  grid-template-columns: 5fr 4fr 5fr;
  align-items: center;
  @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }
`;

const VeBottom = styled(VeInfoRow)`
  padding-bottom: 0px;
  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

export const CardTitle = styled(Title)`
  font-size: 24px !important;
  margin-bottom: 10px;
  font-weight: 700 !important;
`;

const ButtonContainer = styled.div`
  display: flex;
  grid-gap: 7px;
  align-items: center;
`;

const YourVeInfo = (props: YourVeInfoProps) => {
  const intl = useIntl();
  const { data } = useGetManageInfo();
  const [currentSpot, setCurrentSpot] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isClaimKlayLoading, setIsClaimKlayLoading] = useState(false);
  return (
    <RowContainer
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        paddingBottom: 0,
        gridGap: 16,
      }}
      hideborder
    >
      <CardTitle>{intl.formatMessage(messages.veInfo)}</CardTitle>
      <VeInfoRow showborderbottom>
        <Subtitle>{intl.formatMessage(messages.amountLocked)}</Subtitle>
        <Subtitle>{intl.formatMessage(messages.expiry)}</Subtitle>
        <Subtitle>{intl.formatMessage(messages.earned)}</Subtitle>
      </VeInfoRow>
      {props.listTokens.map((token, index) => (
        <VeInfoRow
          style={{
            padding: '2px 0px',
            paddingBottom: index === props.listTokens.length - 1 ? 18 : 0,
          }}
          showborderbottom={index === props.listTokens.length - 1}
        >
          <Subtitle>
            <b style={{ color: 'black', marginRight: 5 }}>
              {token.amount.toLocaleString('en-us', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 5,
              })}
            </b>{' '}
            {token.isKlap ? 'KLAP' : 'KLAP-KLAY LP'}
          </Subtitle>
          <Subtitle>
            <b style={{ color: 'black' }}>{moment(token.expiry * 1000).format('MM/DD/YY HH:mm')}</b>
          </Subtitle>
          <Subtitle
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <b style={{ color: 'black', marginRight: 5 }}>
                {token.earnedKlayKlapLP !== undefined && !token.isKlap
                  ? `${token.earnedKlayKlapLP.toLocaleString('en-us', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 5,
                    })} KLAP`
                  : token.earnedKlap && token.isKlap
                  ? `${token.earnedKlap.toLocaleString('en-us', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 5,
                    })} KLAP`
                  : null}
              </b>
            </div>
            <ButtonContainer>
              {isClaimKlayLoading && index === currentSpot ? (
                <div>
                  <Preloader smallSize />
                </div>
              ) : !token.isKlap ? (
                <DefaultButton
                  className="RowButton"
                  color="blue"
                  onClick={async () => {
                    setIsClaimKlayLoading(true);
                    setCurrentSpot(index);
                    await props.onClaimFromMasterchef();
                    setIsClaimKlayLoading(false);
                  }}
                  title={`${intl.formatMessage(messages.claimReward)} KLAP`}
                />
              ) : (
                <DefaultButton
                  className="RowButton"
                  color="blue"
                  disabled={token.earnedKlap === 0}
                  onClick={async () => {
                    setIsClaimKlayLoading(true);
                    setCurrentSpot(index);
                    await props.onClaim(token.tokenId);
                    setIsClaimKlayLoading(false);
                  }}
                  title={`${intl.formatMessage(messages.claimReward)} KLAP`}
                />
              )}
              {isLoading && index === currentSpot ? (
                <div>
                  <Preloader smallSize />
                </div>
              ) : (
                <DefaultButton
                  className="RowButton"
                  color="blue"
                  disabled={token.earnedKlap === 0}
                  onClick={async () => {
                    if (token.expiry < moment().unix()) {
                      setIsLoading(true);
                      setCurrentSpot(index);
                      await props.onClaim(token.tokenId);
                      setIsLoading(false);
                    } else {
                      props.openModal(
                        token.tokenId,
                        token.amount.toLocaleString('en-us', {
                          maximumSignificantDigits: 4,
                          minimumSignificantDigits: 2,
                        }),
                        moment(token.expiry * 1000).format('MM/DD/YY HH:mm')
                      );
                    }
                  }}
                  title={
                    token.expiry < moment().unix()
                      ? intl.formatMessage(messages.claimUnderlying)
                      : intl.formatMessage(messagesLocal.manage)
                  }
                />
              )}
            </ButtonContainer>
          </Subtitle>
        </VeInfoRow>
      ))}
      <Subtitle style={{ color: '#000', fontWeight: 'bold' }}>
        {intl.formatMessage(messages.veStaking)}
      </Subtitle>
      <Subtitle style={{ color: '#000', fontWeight: 'bold' }}>
        {intl.formatMessage(messages.lpNotes)}
      </Subtitle>
      <VeBottom showborderbottom={false}>
        <div>
          <Subtitle style={{ marginTop: 5, marginBottom: 5 }}>
            {intl.formatMessage(messages.totalKlapVesting)}:{' '}
            <b style={{ marginLeft: 4, marginRight: 4, color: 'black' }}>
              {_.sumBy(props.listTokens, (token) =>
                token.isKlap ? token.amount : 0
              ).toLocaleString('en-us', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
            </b>
          </Subtitle>
          <Subtitle>
            {intl.formatMessage(messages.totalUsdValue)}:{' '}
            <b style={{ marginLeft: 4, marginRight: 4, color: 'black' }}>
              $
              {(
                data.klapTokenPrice *
                _.sumBy(props.listTokens, (token) => (token.isKlap ? token.amount : 0))
              ).toLocaleString('en-us', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
            </b>{' '}
            USD
          </Subtitle>
        </div>
        <div>
          <Subtitle style={{ marginTop: 5, marginBottom: 5 }}>
            {intl.formatMessage(messages.totalKlapKlayVesting)}:{' '}
            <b style={{ marginLeft: 4, marginRight: 4, color: 'black' }}>
              {_.sumBy(props.listTokens, (token) =>
                !token.isKlap ? token.amount : 0
              ).toLocaleString('en-us', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
            </b>{' '}
          </Subtitle>
          <Subtitle>
            {intl.formatMessage(messages.totalUsdValue)}:{' '}
            <b style={{ marginLeft: 4, marginRight: 4, color: 'black' }}>
              $
              {(
                data.klapTokenPrice *
                2 *
                _.sumBy(props.listTokens, (token) => (!token.isKlap ? token.amount : 0))
              ).toLocaleString('en-us', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
            </b>{' '}
            USD
          </Subtitle>
        </div>
      </VeBottom>
      <style jsx={true} global={true}>{`
        .RowButton,
        .RowButton .ConnectButton__inner,
        .RowButton .ConnectButton__inner span {
          height: 30px;
          max-width: 75px;
          font-weight: 400 !important;
          font-size: 12px !important;
          padding: 0px 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      `}</style>
    </RowContainer>
  );
};

export default YourVeInfo;

import { Multicall, ContractCallContext, ContractCallResults } from 'ethereum-multicall';
import _ from 'lodash';
import { useCallback } from 'react';
import { getProvider } from '../../../helpers/config/markets-and-network-config';
import { useProtocolDataContext } from '../../protocol-data-provider';

export function useMultiCall(abi: any[]) {
  const { chainId } = useProtocolDataContext();

  return useCallback(
    async (contractAddress: string | string[], methods: string[][], args: any[][][]) => {
      const provider = getProvider(chainId);
      let multicall: any;
      if (chainId === 8217) {
        multicall = new Multicall({
          multicallCustomContractAddress: '0xc43451a79b7828a2c842ff6fa98ab904eff7d431',
      // @ts-ignore
          ethersProvider: provider,
          tryAggregate: false,
        });
      } else if (chainId === 1001) {
        multicall = new Multicall({
          multicallCustomContractAddress: '0xa1e7c514aed820ccd0ab82297496b5632477ecdf',
      // @ts-ignore
          ethersProvider: provider,
          tryAggregate: false,
        });
      } else if (chainId === 421611) {
        multicall = new Multicall({
          multicallCustomContractAddress: '0x977923a4097cd0c21b272c0644d18b57d3676b8f',
      // @ts-ignore
          ethersProvider: provider,
          tryAggregate: false,
        });
      } else {
        multicall = new Multicall({
      // @ts-ignore
          ethersProvider: provider,
          tryAggregate: false,
        });
      }
      const addresses = typeof contractAddress === 'string' ? [contractAddress] : contractAddress;
      const context: ContractCallContext[] = _.map(_.range(0, addresses.length), (i) => ({
        reference: addresses[i],
        contractAddress: addresses[i],
        abi: abi.length === 0 ? abi[0] : abi[i],
        calls: _.map(_.range(0, methods[i].length), (index) => ({
          reference: methods[i][index] + index,
          methodName: methods[i][index],
          methodParameters: args[i][index],
        })),
      }));
      try {
        const resultsMulticall: ContractCallResults = await multicall.call(context);
        const resultsData = resultsMulticall.results;
        console.log('results', resultsData);

        return _.map(Object.keys(resultsData), (method) =>
          _.map(resultsData[method].callsReturnContext, (calls) => calls.returnValues)
        );
      } catch (e) {
        console.error('Multicall Issue: ' + e);
        return [];
      }
    },
    [abi, chainId]
  );
}

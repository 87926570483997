import React, { useEffect, useState } from 'react';
import TableItemWrapper from '../../../components/BasicTable/TableItemWrapper';
import TableColumn from '../../../components/BasicTable/TableColumn';
import Value from '../../../components/basic/Value';
import LiquidityMiningCard from '../../../components/liquidityMining/LiquidityMiningCard';
import { getAssetInfo, TokenIcon } from '../../../helpers/config/assets-config';

import staticStyles from './VoteTableItem__style';
import ReactSlider from 'react-slider';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import messages from '../messages';
import LiquidityMiningBreakdown from '../../../components/liquidityMining/LiquidityMiningBreakdown';
import ValuePercent from '../../../components/basic/ValuePercent';

const LendBorrowText = styled.div<{ islend: boolean }>`
  color: #ff85a3;
  ${({ islend }) =>
    islend &&
    `
    color: #8855FF;
  `}
  font-size: 8px;
  width: 6px;
  flex: none;
  order: 0;
  flex-grow: 0;
  word-wrap: break-word;
  margin-right: 30px;
  text-align: center;
`;

export interface VoteTableItemProps {
  id: string;
  currencySymbol: string;
  disabled: boolean;
  maxDialutingEmissions: string;
  baseEmissions: string;
  accKlapPerFactorShare: string;
  nftFactor: number;
  globalPercentage: number;
  totalAllocPoint: string;
  allocPoint: string;
  totalSupply: string;
  currentNFT: number;
  isAToken: boolean;
  price: number;
  klapPrice: number;
  setGlobalPercentage: (amount: number) => void;
  name: string;
}

export default function VoteTableItem({
  currencySymbol,
  disabled,
  maxDialutingEmissions,
  baseEmissions,
  setGlobalPercentage,
  globalPercentage,
  nftFactor,
  allocPoint,
  totalAllocPoint,
  totalSupply,
  currentNFT,
  isAToken,
  price,
  klapPrice,
  name,
}: VoteTableItemProps) {
  const factor = Number(totalAllocPoint) !== 0 ? Number(allocPoint) / Number(totalAllocPoint) : 1;
  const asset = getAssetInfo(currencySymbol);
  const [percentage, setPercentage] = useState(0);
  const [newEmissions, setNewEmissions] = useState(0);
  const [newAPR, setNewAPR] = useState(0);
  const intl = useIntl();

  useEffect(() => {
    setPercentage(0);
  }, [currentNFT]);

  useEffect(() => {
    if (Number(totalAllocPoint) === 0 || Number(totalSupply) === 0) {
      return;
    }

    if (globalPercentage === 0) {
      const newEmission = factor * (Number(baseEmissions) + Number(maxDialutingEmissions));
      const newAPR = (newEmission * klapPrice) / (Number(totalSupply) * price);
      setNewEmissions(newEmission);
      setNewAPR(newAPR);
      return;
    }

    const newVote = (percentage / globalPercentage) * nftFactor;
    const newFactor = (Number(allocPoint) + newVote) / (Number(totalAllocPoint) + nftFactor);

    const newEmission = newFactor * (Number(baseEmissions) + Number(maxDialutingEmissions));
    const newAPR = (newEmission * klapPrice) / (price * Number(totalSupply));

    setNewEmissions(newEmission);
    setNewAPR(newAPR);
  }, [
    globalPercentage,
    totalSupply,
    totalAllocPoint,
    baseEmissions,
    maxDialutingEmissions,
    nftFactor,
    allocPoint,
    klapPrice,
    price,
  ]);

  return (
    <TableItemWrapper onClick={() => {}} className="VoteTableItem" withGoToTop={true}>
      <TableColumn className="VoteTableItem__column">
        <LendBorrowText islend={isAToken}>
          {isAToken ? intl.formatMessage(messages.lend) : intl.formatMessage(messages.borrow)}
        </LendBorrowText>
        <TokenIcon
          tokenSymbol={currencySymbol}
          height={35}
          width={35}
          tokenFullName={name || asset.name}
          className="VoteTableItem__token"
        />
      </TableColumn>
      <TableColumn className="VoteTableItem__column">
        <Value
          value={factor * (Number(baseEmissions) + Number(maxDialutingEmissions))}
          compact={true}
          maximumValueDecimals={2}
          withoutSymbol={true}
          tooltipId={`market-size-${asset.symbol}`}
          className="VoteTableItem__value"
        />
      </TableColumn>
      <TableColumn className="VoteTableItem__column">
        <ValuePercent
          value={
            (factor * (Number(baseEmissions) + Number(maxDialutingEmissions)) * klapPrice) /
            (Number(totalSupply) * price)
          }
        />
      </TableColumn>

      <TableColumn className="VoteTableItem__column">
        <Value
          value={newEmissions}
          compact={true}
          maximumValueDecimals={2}
          className="VoteTableItem__value"
        />
      </TableColumn>

      <TableColumn className="VoteTableItem__column">
        <ValuePercent value={newAPR} />
      </TableColumn>

      <TableColumn className="VoteTableItem__column">
        {/*<ReactSlider
          disabled={disabled}
          className="VoteTableItem__slider"
          thumbClassName="VoteTableItem__thumb"
          trackClassName="VoteTableItem__track"
          onChange={(num) => {
            setGlobalPercentage(num);
            setPercentage(num);
          }}
          value={percentage}
          renderThumb={(props, state) => <div {...props}></div>}
        />*/}
        <div className="VoteTableItem__percentage">{percentage}%</div>
      </TableColumn>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </TableItemWrapper>
  );
}

import React from 'react';
import { useIntl } from 'react-intl';

import TableItem from '../../../dashboard/components/DashboardTable/TableItem';
import TableValueCol from '../../../dashboard/components/DashboardTable/TableValueCol';
import TableButtonsWrapper from '../../../dashboard/components/DashboardTable/TableButtonsWrapper';
import TableButtonCol from '../../../dashboard/components/DashboardTable/TableButtonCol';
import TableAprCol from '../../../dashboard/components/DashboardTable/TableAprCol';

import defaultMessages from '../../../../defaultMessages';

import { BorrowTableItem } from './types';

export default function BorrowItem({
  reserve: { symbol },
  uiColor,
  currentBorrows,
  currentBorrowsUSD,
  borrowRate,
  avg30DaysVariableRate,
  borrowRateMode,
  onSwitchToggle,
  isActive,
  isFrozen,
  borrowingEnabled,
  stableBorrowRateEnabled,
  repayLink,
  borrowLink,
  index,
  sincentivesAPR,
  borrowFarmAPY,
  borrowVeFarmAPY,
  borrowKlayAPY,
  vincentivesAPR,
  name,
  ...rest
}: BorrowTableItem) {
  const intl = useIntl();

  return (
    <TableItem name={name} tokenSymbol={symbol} color={uiColor} {...rest}>
      <TableValueCol
        value={Number(currentBorrows)}
        subValue={Number(currentBorrowsUSD)}
        tooltipId={`borrow-${symbol}__${index}`}
      />
      <TableAprCol
        baseVal={Number(vincentivesAPR)}
        farmAPY={borrowFarmAPY}
        veFarmAPY={borrowVeFarmAPY}
        klayAPY={borrowKlayAPY}
        symbol={symbol}
        type={'borrow'}
        id={index + ''}
      />

      <TableButtonsWrapper>
        <TableButtonCol
          disabled={!isActive || !borrowingEnabled || isFrozen}
          title={intl.formatMessage(defaultMessages.borrow)}
          linkTo={borrowLink}
        />
        <TableButtonCol
          disabled={!isActive}
          title={intl.formatMessage(defaultMessages.repay)}
          linkTo={repayLink}
          withoutBorder={true}
        />
      </TableButtonsWrapper>
    </TableItem>
  );
}

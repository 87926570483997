//import { MAX_UINT_AMOUNT } from '@aave/protocol-js';
import { useWeb3React } from '@web3-react/core';
import { providers, Contract, BigNumber } from 'ethers';
import { getProvider } from '../../helpers/config/markets-and-network-config';
import { useProtocolDataContext } from '../../libs/protocol-data-provider';
import LockdropABI from '../../contracts/Lockdrop.json';
import ERC20ABI from '../../contracts/ERC20.json';
import RewardClaimerABI from '../../contracts/RewardClaimer.json';
import { useUserWalletDataContext } from '../../libs/web3-data-provider';
import Caver, { AbiItem } from 'caver-js';
import waitForTxn from '../../helpers/wait-for-txn';
import { useKlipContext } from '../../components/wrappers/ScreensWrapper';
// @ts-ignore
import { prepare } from 'klip-sdk';
import _ from 'lodash';
import { MAX_UINT_AMOUNT } from '@aave/protocol-js';
import { parseEther } from 'ethers/lib/utils';

export const useServices = () => {
  const setShowQRCode = useKlipContext();
  const { currentAccount: userId, currentProviderName } = useUserWalletDataContext();
  const { chainId } = useProtocolDataContext();
  const { library: provider } = useWeb3React<providers.Web3Provider>();
  const sendObject = {
    from: (window as any).klaytn ? (window as any).klaytn?.selectedAddress : null,
    gas: 1800000,
  };
  const mmObject = { gasPrice: 250000000000 };
  const lockdrop = (contract: string, transaction?: object) => {
    if (currentProviderName === 'kaikas') {
      // @ts-ignore
      const caver = new Caver(provider!.provider);
      return new caver.klay.Contract(LockdropABI as AbiItem[], contract) as any;
    } else if (currentProviderName === 'klip') {
      return prepare.executeContract({
        bappName: 'Klap',
        to: contract,
        ...transaction,
      });
    }
    const topHolderSigner = provider ? provider!.getSigner(userId) : getProvider(chainId);
    // @ts-ignore
    return new Contract(contract, LockdropABI, topHolderSigner);
  };

  const erc20 = (aToken: string, transaction?: object) => {
    if (currentProviderName === 'kaikas') {
      // @ts-ignore
      const caver = new Caver(provider!.provider);
      return new caver.klay.Contract(ERC20ABI as AbiItem[], aToken) as any;
    } else if (currentProviderName === 'klip') {
      return prepare.executeContract({
        bappName: 'Klap',
        to: aToken,
        ...transaction,
      });
    }
    const topHolderSigner = provider ? provider!.getSigner(userId) : getProvider(chainId);
    // @ts-ignore
    return new Contract(aToken, ERC20ABI, topHolderSigner);
  };

  const rewardClaimer = (contract: string, transaction?: object) => {
    if (currentProviderName === 'kaikas') {
      // @ts-ignore
      const caver = new Caver(provider!.provider);
      return new caver.klay.Contract(RewardClaimerABI as AbiItem[], contract) as any;
    } else if (currentProviderName === 'klip') {
      return prepare.executeContract({
        bappName: 'Klap',
        to: contract,
        ...transaction,
      });
    }
    const topHolderSigner = provider ? provider!.getSigner(userId) : getProvider(chainId);
    // @ts-ignore
    return new Contract(contract, RewardClaimerABI, topHolderSigner);
  };

  const claimTokens = async (contract: string, aToken: string) => {
    try {
      if (currentProviderName === 'kaikas') {
        const returnVal = await lockdrop(contract).methods.claim(aToken).send(sendObject);
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await lockdrop(contract, {
          value: '0',
          abi: JSON.stringify(_.find(LockdropABI, (func) => func.name === 'claim')),
          params: JSON.stringify([aToken]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        const claim = await lockdrop(contract).claim(aToken, mmObject);
        await claim.wait();
      }
    } catch (e) {}
  };

  const claimKlap = async (contract: string) => {
    try {
      if (currentProviderName === 'kaikas') {
        const returnVal = await rewardClaimer(contract).methods.claimKLAP().send(sendObject);
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await rewardClaimer(contract, {
          value: '0',
          abi: JSON.stringify(_.find(RewardClaimerABI, (func) => func.name === 'claimKLAP')),
          params: JSON.stringify([]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        const claim = await rewardClaimer(contract).claimKLAP(mmObject);
        await claim.wait();
      }
    } catch (e) {}
  };

  const claimKlay = async (contract: string) => {
    try {
      if (currentProviderName === 'kaikas') {
        const returnVal = await rewardClaimer(contract).methods.claimKLAY().send(sendObject);
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await rewardClaimer(contract, {
          value: '0',
          abi: JSON.stringify(_.find(RewardClaimerABI, (func) => func.name === 'claimKLAY')),
          params: JSON.stringify([]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        const claim = await rewardClaimer(contract).claimKLAY(mmObject);
        await claim.wait();
      }
    } catch (e) {}
  };

  const depositTokens = async (
    contract: string,
    amount: number,
    decimal: number,
    aToken: string,
    showError?: () => void
  ) => {
    try {
      const amount_output = decimal === 18 ? parseEther('' + amount) : amount * 10 ** decimal;
      if (currentProviderName === 'kaikas') {
        try {
          const returnVal = await lockdrop(contract)
            .methods.deposit(amount_output, aToken)
            .send(sendObject);
          await waitForTxn(provider, returnVal.transactionHash);
        } catch (e) {
          console.error('e', e);
          showError!();
        }
      } else if (currentProviderName === 'klip') {
        console.log([amount_output.toString(), aToken]);
        const returnVal = await lockdrop(contract, {
          value: '0',
          abi: JSON.stringify(_.find(LockdropABI, (func) => func.name === 'deposit')),
          params: JSON.stringify([amount_output.toString(), aToken]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        try {
          const deposit = await lockdrop(contract).deposit(amount_output, aToken, mmObject);
          await deposit.wait();
        } catch (e) {
          console.error(e);
          if (e.code === -32603) {
            showError!();
          }
        }
      }
    } catch (e) {}
  };

  const approveTokens = async (contract: string, aToken: string) => {
    try {
      if (currentProviderName === 'kaikas') {
        console.log('go here', contract);
        console.log('atoken', aToken);
        const returnVal = await erc20(aToken)
          .methods.approve(contract, MAX_UINT_AMOUNT)
          .send(sendObject);
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await erc20(aToken, {
          value: '0',
          abi: JSON.stringify(_.find(ERC20ABI, (func) => func.name === 'approve')),
          params: JSON.stringify([contract, MAX_UINT_AMOUNT]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        const claim = await erc20(aToken).approve(contract, MAX_UINT_AMOUNT, mmObject);
        await claim.wait();
      }
    } catch (e) {
      console.error('e', e);
    }
  };

  return {
    claimTokens,
    claimKlap,
    claimKlay,
    depositTokens,
    approveTokens,
  };
};

import logo from '../../../images/klapLogo.svg';
import klayLogo from '../../../images/klay.png';
import kokoaLogo from '../../../images/kokoa.svg';

export const KokoaLogo = ({ style, height = 40 }: any) => {
  return (
    <img style={{ ...style, height: height, marginLeft: 5 }} src={kokoaLogo} alt={'sKOKOA Logo'} />
  );
};

export const KlayLogo = ({ style, height = 40 }: any) => {
  return (
    <img style={{ ...style, height: height, marginLeft: 5 }} src={klayLogo} alt={'KLAY Logo'} />
  );
};

const Logo = ({ style, height = 40 }: any) => {
  return <img style={{ height: height, marginLeft: 5, ...style }} src={logo} alt={'KLAP Logo'} />;
};

export default Logo;

    // @ts-ignore
import { AbstractConnectorArguments, ConnectorUpdate } from '@web3-react/types';
import { AbstractConnector } from '@web3-react/abstract-connector';
import axios from 'axios';
import QRcode from 'qrcode';
import { KLIP_REQUEST_ADDRESS } from '../../../../constants';
// @ts-ignore
import { prepare } from 'klip-sdk';
import Web3 from 'web3';
import Caver from 'caver-js';

export type SendReturnResult = { result: any };
export type SendReturn = any;

export type Send = (method: string, params?: any[]) => Promise<SendReturnResult | SendReturn>;
export type SendOld = ({ method }: { method: string }) => Promise<SendReturnResult | SendReturn>;

function parseSendReturn(sendReturn: SendReturnResult | SendReturn): any {
  return sendReturn.hasOwnProperty('result') ? sendReturn.result : sendReturn;
}

export class NoEthereumProviderError extends Error {
  public constructor() {
    super();
    this.name = this.constructor.name;
    this.message = 'No Ethereum provider was found on window.ethereum.';
  }
}

export class UserRejectedRequestError extends Error {
  public constructor() {
    super();
    this.name = this.constructor.name;
    this.message = 'The user rejected the request.';
  }
}

interface KlipArguments extends AbstractConnectorArguments {
  showModal: () => void;
  closeModal: () => void;
}

export class KlipConnector extends AbstractConnector {
  private request_key = '';
  private responseData = undefined;
  private intervalCheckResult: any;
  private account = '';
  private auth = false;
  private showModal;
  private closeModal;
  private provider: any;

  constructor(kwargs: KlipArguments) {
        // @ts-ignore
    super(kwargs);

    this.handleNetworkChanged = this.handleNetworkChanged.bind(this);
    this.handleChainChanged = this.handleChainChanged.bind(this);
    this.handleAccountsChanged = this.handleAccountsChanged.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.showModal = kwargs.showModal.bind(this);
    this.closeModal = kwargs.closeModal.bind(this);
    const web3 = new Caver(
      new Caver.providers.HttpProvider('https://public-node-api.klaytnapi.com/v1/cypress')
    );
    // @ts-ignore
    this.provider = web3.currentProvider;
  }

  private initData() {
    this.request_key = '';
    this.responseData = undefined;
    this.auth = false;
  }

  private async genQRcode() {
    this.initData();
    const response = await prepare.auth({ bappName: 'Klap' });
    console.log('response key', response.request_key);
    this.request_key = response.request_key;
    if (!response.err) {
      QRcode.toCanvas(
        document.getElementById('qrcode'),
        'https://klipwallet.com/?target=/a2a?request_key=' + response.request_key,
        (error) => {
          console.log('error', error);
          this.intervalCheckResult = setInterval(() => {
            console.log('request', this.request_key);
            this.getResult(this.request_key);
          }, 2000);
        }
      );
    }
  }

  private async getResult(request_key: string) {
    try {
      console.log('request key', request_key);
      var url = KLIP_REQUEST_ADDRESS + request_key;
      const res = await axios.get(url);
      if (res.data.status === 'completed') {
        this.account = res.data.result.klaytn_address;
        this.responseData = res.data.result.klaytn_address;
        this.auth = true;
        clearInterval(this.intervalCheckResult);
      }
    } catch (e) {
      clearInterval(this.intervalCheckResult);
      return Promise.reject(e);
    }
  }

  private handleChainChanged(chainId: string | number): void {
    this.emitUpdate({ chainId, provider: this.getProvider() });
  }

  private handleAccountsChanged(accounts: string[]): void {
    if (accounts.length === 0) {
      this.emitDeactivate();
    } else {
      this.emitUpdate({ account: accounts[0] });
    }
  }

  private handleClose(code: number, reason: string): void {
    this.emitDeactivate();
  }

  private handleNetworkChanged(networkId: string | number): void {
    this.emitUpdate({ chainId: networkId, provider: this.getProvider() });
  }

  private async checkResponse() {
    try {
      while (this.responseData === undefined) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      return this.responseData;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async activate(): Promise<ConnectorUpdate> {
    if (this.account)
      return {
        provider: { provider: this.provider, selectedAddress: this.account },
        ...{ account: this.account },
      };

    this.showModal();
    this.genQRcode();
    await this.checkResponse();
    this.closeModal();
    const account = this.account;
    this.provider.selectedAddress = account;
    return {
      provider: this.provider,
      ...(account ? { account: account } : {}),
    };
  }

  public async getProvider(): Promise<any> {
    return this.provider;
  }

  public async getChainId(): Promise<number | string> {
    return 8217;
  }

  public async getAccount(): Promise<null | string> {
    return this.account;
  }

  public deactivate() {
    this.auth = false;
  }

  public async isAuthorized() {
    return this.auth;
  }
}

import React from 'react';
import { useIntl } from 'react-intl';

import MobileCardWrapper from '../../../../components/wrappers/MobileCardWrapper';
import Row from '../../../../components/basic/Row';
import Value from '../../../../components/basic/Value';
import NoData from '../../../../components/basic/NoData';
import Link from '../../../../components/basic/Link';
import DefaultButton from '../../../../components/basic/DefaultButton';
import AMPLWarning from '../../../../components/AMPLWarning';

import defaultMessages from '../../../../defaultMessages';
import messages from './messages';

import { BorrowTableItem } from './types';
import TableAprCol from '../../../dashboard/components/DashboardTable/TableAprCol';

export default function BorrowMobileCard({
  reserve: { symbol },
  currentBorrows,
  currentBorrowsUSD,
  borrowRate,
  avg30DaysVariableRate,
  borrowRateMode,
  onSwitchToggle,
  isActive,
  isFrozen,
  borrowingEnabled,
  stableBorrowRateEnabled,
  repayLink,
  borrowLink,
  borrowFarmAPY,
  borrowKlayAPY,
  borrowVeFarmAPY,
  vincentivesAPR,
  name,
  index,
}: BorrowTableItem) {
  const intl = useIntl();

  return (
    <>
      <MobileCardWrapper name={name} symbol={symbol}>
        <Row title={intl.formatMessage(messages.secondTableColumnTitle)} withMargin={true}>
          <Value
            value={Number(currentBorrows)}
            subValue={Number(currentBorrowsUSD)}
            subSymbol="USD"
          />
        </Row>

        <Row title={intl.formatMessage(messages.apyRowTitle)} withMargin={true}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {borrowingEnabled ? (
              <TableAprCol
                className="APRColMobile"
                baseVal={Number(vincentivesAPR)}
                farmAPY={borrowFarmAPY}
                veFarmAPY={borrowVeFarmAPY}
                klayAPY={borrowKlayAPY}
                symbol={symbol}
                type={'borrow'}
                id={'' + index}
              />
            ) : (
              <NoData color="dark" />
            )}
          </div>
        </Row>

        <Row
          title={intl.formatMessage(messages.borrowMore)}
          className="Row__center"
          withMargin={true}
        >
          <Link
            to={borrowLink}
            className="ButtonLink"
            disabled={!isActive || !borrowingEnabled || isFrozen}
          >
            <DefaultButton
              title={intl.formatMessage(defaultMessages.borrow)}
              color="dark"
              disabled={!isActive || !borrowingEnabled || isFrozen}
            />
          </Link>
        </Row>

        <Row title={intl.formatMessage(messages.repayYourBorrow)} className="Row__center">
          <Link to={repayLink} className="ButtonLink" disabled={!isActive}>
            <DefaultButton
              title={intl.formatMessage(defaultMessages.repay)}
              color="blue"
              transparent={true}
              disabled={!isActive}
            />
          </Link>
        </Row>
      </MobileCardWrapper>

      {symbol === 'AMPL' && <AMPLWarning />}
    </>
  );
}

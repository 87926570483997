import React, { useState } from 'react';

import TableItem from '../../../../components/BasicAssetsTable/TableItem';
import TableColumn from '../../../../components/BasicTable/TableColumn';
import Value from '../../../../components/basic/Value';

import { FaucetTableItem } from './types';
import DefaultButton from '../../../../components/basic/DefaultButton';
import { useWeb3React } from '@web3-react/core';
import { AbiItem } from 'caver-js';
import { providers, Contract } from 'ethers';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { useKlipContext } from '../../../../components/wrappers/ScreensWrapper';
import { getProvider } from '../../../../helpers/config/markets-and-network-config';
import waitForTxn from '../../../../helpers/wait-for-txn';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import { useUserWalletDataContext } from '../../../../libs/web3-data-provider';
import ERC20Mintable from '../../../../contracts/ERC20Mintable.json';
import messages from './messages';
// @ts-ignore
import { prepare } from 'klip-sdk';
import Preloader from '../../../../components/basic/Preloader';

export default function FaucetItem({
  symbol,
  id,
  userId,
  walletBalance,
  underlyingAsset,
}: FaucetTableItem) {
  const setShowQRCode = useKlipContext();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const { currentProviderName } = useUserWalletDataContext();
  const { chainId: currentChainId } = useProtocolDataContext();
  const { library: provider } = useWeb3React<providers.Web3Provider>();
  const sendObject = {
    from: (window as any).klaytn ? (window as any).klaytn?.selectedAddress : null,
    gas: 1800000,
  };

  const faucetService = (transaction?: object) => {
    const topHolderSigner = provider ? provider!.getSigner(userId) : getProvider(currentChainId);

    if (currentProviderName === 'kaikas') {
      // @ts-ignore
      const caver = new Caver(provider!.provider);
      return new caver.klay.Contract(ERC20Mintable as AbiItem[], underlyingAsset) as any;
    } else if (currentProviderName === 'klip') {
      return prepare.executeContract({
        bappName: 'Klap',
        to: underlyingAsset,
        ...transaction,
      });
    }
    // @ts-ignore
    return new Contract(underlyingAsset, ERC20Mintable, topHolderSigner);
  };

  const handleGetTransactions = async () => {
    console.log('Symbol', symbol);
    if (symbol === 'KLAY') {
      window.open('https://baobab.wallet.klaytn.foundation/faucet');
      return;
    }
    setIsLoading(true);
    try {
      if (currentProviderName === 'kaikas') {
        const returnVal = await faucetService()
          .methods.mint('100000000000000000000')
          .send(sendObject);
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await faucetService({
          value: '0',
          abi: JSON.stringify(_.find(ERC20Mintable, (func) => func.name === 'mint')),
          params: JSON.stringify(['100000000000000000000']),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        const withdraw = await faucetService(sendObject).mint('100000000000000000000');
        await withdraw.wait();
      }
    } catch (e) {}
    setIsLoading(false);
  };

  return (
    <TableItem symbol={symbol} url={''} disabled>
      <TableColumn>
        {!userId ? (
          <span>—</span>
        ) : (
          <Value value={Number(walletBalance)} maximumValueDecimals={5} minimumValueDecimals={5} />
        )}
      </TableColumn>
      <TableColumn className="ColumnMiniFaucet">
        {!userId ? (
          <span>—</span>
        ) : isLoading ? (
          <Preloader smallSize />
        ) : (
          <DefaultButton
            color="blue"
            className="MintButton"
            title={intl.formatMessage(messages.mint)}
            onClick={() => handleGetTransactions()}
          />
        )}
      </TableColumn>
    </TableItem>
  );
}

import React from 'react';

import TableItem from '../../../../components/BasicAssetsTable/TableItem';
import TableColumn from '../../../../components/BasicTable/TableColumn';
import Value from '../../../../components/basic/Value';
import NoData from '../../../../components/basic/NoData';
import { isAssetStable } from '../../../../helpers/config/assets-config';

import { DepositTableItem } from './types';
import LiquidityMiningBreakdown from '../../../../components/liquidityMining/LiquidityMiningBreakdown';

export default function DepositItem({
  id,
  symbol,
  underlyingAsset,
  walletBalance,
  walletBalanceInUSD,
  liquidityRate,
  avg30DaysLiquidityRate,
  userId,
  borrowingEnabled,
  isFreezed,
  aincentivesAPR,
  depositFarmAPY,
  depositVeFarmAPY,
  depositKlayAPY,
  name,
  index,
}: DepositTableItem) {
  const url = `/deposit/${underlyingAsset}-${id}`;

  return (
    <TableItem name={name} symbol={symbol} url={url} isFreezed={isFreezed} darkOnDarkMode={true}>
      <TableColumn>
        {!userId || Number(walletBalance) <= 0 ? (
          <NoData color="dark" />
        ) : (
          <Value
            value={Number(walletBalance)}
            subValue={walletBalanceInUSD}
            maximumSubValueDecimals={2}
            subSymbol="USD"
            maximumValueDecimals={isAssetStable(symbol) ? 2 : 5}
            minimumValueDecimals={isAssetStable(symbol) ? 2 : 5}
          />
        )}
      </TableColumn>

      {!isFreezed && (
        <TableColumn>
          <LiquidityMiningBreakdown
            currencySymbol={symbol}
            klayValue={depositKlayAPY}
            baseValue={Number(liquidityRate)}
            farmValue={depositFarmAPY || 0}
            boostValue={depositVeFarmAPY || 0}
            symbol={symbol}
            type={'lend'}
            index={'' + index}
          />
        </TableColumn>
      )}
    </TableItem>
  );
}

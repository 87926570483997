import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import MobileCardWrapper from '../../../../components/wrappers/MobileCardWrapper';
import Row from '../../../../components/basic/Row';
import NoData from '../../../../components/basic/NoData';
import Value from '../../../../components/basic/Value';
import LiquidityMiningCard from '../../../../components/liquidityMining/LiquidityMiningCard';
import { isAssetStable } from '../../../../helpers/config/assets-config';

import messages from './messages';

import { BorrowTableItem } from './types';
import TableAprCol from '../../../dashboard/components/DashboardTable/TableAprCol';
import LiquidityMiningBreakdown from '../../../../components/liquidityMining/LiquidityMiningBreakdown';

export default function BorrowMobileCard({
  id,
  symbol,
  underlyingAsset,
  availableBorrows,
  availableBorrowsInUSD,
  stableBorrowRate,
  variableBorrowRate,
  avg30DaysVariableRate,
  stableBorrowRateEnabled,
  userId,
  isFreezed,
  sincentivesAPR,
  borrowFarmAPY,
  borrowKlayAPY,
  borrowVeFarmAPY,
  vincentivesAPR,
  name,
}: BorrowTableItem) {
  const intl = useIntl();
  const history = useHistory();

  const url = `/borrow/${underlyingAsset}-${id}`;

  return (
    <MobileCardWrapper
      name={name}
      onClick={() => history.push(url)}
      symbol={symbol}
      withGoToTop={true}
      disabled={isFreezed}
    >
      <Row title={intl.formatMessage(messages.availableToBorrow)} withMargin={true}>
        {!userId || Number(availableBorrows) <= 0 ? (
          <NoData color="dark" />
        ) : (
          <Value
            value={Number(availableBorrows)}
            subValue={availableBorrowsInUSD}
            subSymbol="USD"
            maximumSubValueDecimals={2}
            minimumValueDecimals={isAssetStable(symbol) ? 2 : 5}
            maximumValueDecimals={isAssetStable(symbol) ? 2 : 5}
          />
        )}
      </Row>

      {!isFreezed && (
        <Row title={intl.formatMessage(messages.variableAPY)} withMargin={true}>
          <TableAprCol
            baseVal={Number(vincentivesAPR)}
            farmAPY={borrowFarmAPY}
            veFarmAPY={borrowVeFarmAPY}
            klayAPY={borrowKlayAPY}
            symbol={symbol}
            type={'borrow'}
            id={id + ''}
          />
        </Row>
      )}
    </MobileCardWrapper>
  );
}

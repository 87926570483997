import React from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import messages from './messages';
import staticStyles from './style';
import Logo from '../basic/Logo';
import { useGetMarketInfo } from '../../modules/markets/screens/Markets/hooks';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useThemeContext } from '@aave/aave-ui-kit';

export const APRSeparation = styled.div`
  grid-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const IndividualContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  justify-content: flex-end;
`;

export const ToolTipText = styled.div`
  color: white;
  font-size: 14px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const TooltipLink = styled(ToolTipText)<{ absolute?: boolean }>`
  color: #1fffb4;
  text-decoration: underline;
  cursor: pointer;

  transition: 0.2s;
  &:hover {
    opacity: 0.9;
    text-decoration: underline;
  }
`;

export const APRContainer = styled.div`
  display: flex;
  grid-gap: 4px;
  justify-content: flex-end;
`;

interface PriceButtonProps {
  className?: string;
  size?: 'small' | 'normal' | 'medium';
}

export default function PriceButton({ className, size = 'normal' }: PriceButtonProps) {
  const intl = useIntl();
  const { data } = useGetMarketInfo();
  const { sm } = useThemeContext();
  return (
    <>
      <button
        data-tip={`price-button`}
        data-for={'price-tooltip'}
        className={classNames('PriceButton', `PriceButton__${size}`, className)}
        type="button"
        disabled={sm}
      >
        <div className="PriceButton__inner">
          <Logo height={26} style={{ marginLeft: '0px !important', marginRight: 2 }} />
          <span>${data.klapPrice.toFixed(3)}</span>
        </div>
      </button>
      {/* @ts-ignore */}
      <ReactTooltip
        className="PriceButton__tooltip"
        id={`price-tooltip`}
        effect="solid"
        place={'bottom'}
        event={'click'}
        clickable
        arrowColor="transparent"
      >
        <APRSeparation>
          <IndividualContainer>
            <ToolTipText>
              <Logo height={20} style={{ marginLeft: 0 }} /> {intl.formatMessage(messages.price)}:
            </ToolTipText>
            <ToolTipText>{intl.formatMessage(messages.yourBalance)}:</ToolTipText>
            <ToolTipText>{intl.formatMessage(messages.buyKlap)}:</ToolTipText>
          </IndividualContainer>
          <IndividualContainer>
            <APRContainer>
              <ToolTipText style={{ color: '#B9C0CA' }}>$</ToolTipText>
              <ToolTipText style={{ color: '#fff' }}>{data.klapPrice.toFixed(2)}</ToolTipText>
            </APRContainer>
            <APRContainer>
              <ToolTipText style={{ color: '#fff' }}>{data.klapBalance.toFixed(2)}</ToolTipText>
              <ToolTipText style={{ color: '#B9C0CA' }}>KLAP</ToolTipText>
            </APRContainer>
            <APRContainer>
              <TooltipLink
                href={`https://app.claimswap.org/swap?tokenA=0x0000000000000000000000000000000000000000&tokenB=0xd109065ee17E2dC20b3472a4d4fB5907BD687D09`}
                as={'a'}
                target={'_blank'}
              >
                {intl.formatMessage(messages.tradeNow)}
              </TooltipLink>
              /
              <TooltipLink
                href={`https://www.gate.io/zh/trade/KLAP_USDT`}
                as={'a'}
                target={'_blank'}
              >
                {intl.formatMessage(messages.tradeOnGate)}
              </TooltipLink>
            </APRContainer>
          </IndividualContainer>
        </APRSeparation>
      </ReactTooltip>
      <style jsx={true}>{staticStyles}</style>
      <style jsx={true}>{`
        .PriceButton {
          &:hover {
            .PriceButton__inner {
              opacity: 0.7;
            }
          }

          &__inner {
            background: #000;
            color: #fff;
            height: 50px;
          }
        }

        .PriceButton__normal,
        .PriceButton__medium {
          &:hover {
            .PriceButton__inner {
              opacity: 0.7;
            }
          }

          .PriceButton__inner {
            background: #2c70f2;
            color: ${'#fff'};
          }
        }
      `}</style>
    </>
  );
}

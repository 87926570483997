import React from 'react';

import TableCol from '../TableCol';

import staticStyles from './style';
import LiquidityMiningBreakdown from '../../../../../components/liquidityMining/LiquidityMiningBreakdown';

interface TableAprColProps {
  baseVal: number;
  farmAPY?: number;
  veFarmAPY?: number;
  klayAPY?: number;
  symbol?: string;
  className?: string;
  type: string;
  id: string;
}

export default function TableAprCol({
  farmAPY,
  veFarmAPY,
  klayAPY,
  type,
  baseVal,
  symbol,
  className,
  id,
}: TableAprColProps) {
  return (
    <TableCol className={className}>
      <LiquidityMiningBreakdown
        baseValue={baseVal}
        farmValue={farmAPY || 0}
        boostValue={veFarmAPY || 0}
        klayValue={klayAPY || 0}
        currencySymbol={symbol}
        type={type}
        index={id}
      />

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </TableCol>
  );
}

import {
  assetsList as assetsListFromKit,
  Asset,
  assetsOrder as assetsOrderFromKit,
  STABLE_ASSETS as stableAssetsFromKit,
} from '@aave/aave-ui-kit';
import Klay from '../../images/klay.svg';
import BNB from '../../images/bnb.svg';
import BUSD from '../../images/busd.svg';
import SOL from '../../images/sol.svg';
import OUSDT from '../../images/oUSDT.svg';
import KSD from '../../images/ksd.png';

export const assetsList: Asset[] = [
  ...assetsListFromKit,
  {
    name: 'Klaytn',
    symbol: 'KLAY',
    formattedSymbol: 'KLAY',
    color: '#4F473B',
    icon: Klay,
  },
  {
    name: 'Wrapped BNB',
    symbol: 'WBNB',
    formattedSymbol: 'WBNB',
    color: '#F0B90B',
    icon: BNB,
  },
  {
    name: 'Wrapped BUSD',
    symbol: 'WBUSD',
    formattedSymbol: 'WBNB',
    color: '#F0B90B',
    icon: BUSD,
  },
  {
    name: 'Wrapped SOL',
    symbol: 'SOL',
    formattedSymbol: 'WSOL',
    color: '#00FFA3',
    icon: SOL,
  },
  {
    name: 'USDT (Orbit)',
    symbol: 'OUSDT',
    formattedSymbol: 'oUSDT',
    color: '#27A17B',
    icon: OUSDT,
  },
  {
    name: 'Kokoa Stable Dollar',
    symbol: 'KSD',
    formattedSymbol: 'KSD',
    color: '#FFF103',
    icon: KSD,
  },
];

export const assetsOrder: string[] = assetsOrderFromKit;
export const stableAssets: string[] = stableAssetsFromKit;

import { ChainId } from '@aave/contract-helpers';
import { MarketDataType } from '../../helpers/config/types';

import * as logos from './images';

export enum CustomMarket {
  proto_baobab = 'proto_baobab',
  proto_cypress = 'proto_cypress',
  proto_kovan = 'proto_kovan',
  proto_mainnet = 'proto_mainnet',
  proto_avalanche = 'proto_avalanche',
  proto_matic = 'proto_matic',
  proto_mumbai = 'proto_mumbai',
  amm_kovan = 'amm_kovan',
  amm_mainnet = 'amm_mainnet',
  proto_fuji = 'proto_fuji',
}

export const marketsData: { [key in keyof typeof CustomMarket]: MarketDataType } = {
  [CustomMarket.proto_cypress]: {
    chainId: 8217,
    logo: logos.aavev2Logo,
    activeLogo: logos.aavev2ActiveLogo,
    aTokenPrefix: 'A',
    enabledFeatures: {
      faucet: false,
      governance: true,
      staking: true,
      incentives: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x78b6ADDE60A9181C1889913D31906bbF5C3795dD',
      LENDING_POOL: '0x1b9c074111ec65E1342Ea844f7273D5449D2194B',
      WETH_GATEWAY: '0x3a2796dB4e040Ecd6007CD5E2438d8199920cA0d',
      MULTIFEE_DISTRIBUTION: '0x517c53C69ffb61CD4D303fcbE97ddb4Dd6cF6c51',
      CHEFS_INCENTIVE_CONTROLLER: '0x422ABB57E4Bb7D46032852b884b7bB4Cc4A39CC7',
      MASTERCHEF: '0x05476ead246c5006891088e71329CBCa6B06AFB2',
      MAIN_TOKEN: '0xd109065ee17E2dC20b3472a4d4fB5907BD687D09',
      LP_TOKEN: '0xeb97b3e45f28685c279b549a41da5eb68581cb3f',
      SKOKOA: '0x666e58391280a06a7cb380c1741376e0b3dd7531',
      VE: '0x9eabaA0007FD06f4e59dD52F0C6fdebec98cfCD1',
      CLAIMSWAP_ROUTER: '0xef71750c100f7918d6ded239ff1cf09e81dea92d',
      MULTIREWARDER: '0x97D48A5C930fa9432FA8b83671Ec79A89Bbe643a',
      LOCKDROP_CONTRACTS: {
        ONE_MONTH: '0xBc317fdC1B7DB0a88995C36e85cd76C971753a76',
        THREE_MONTH: '0x6736a0D262dF159c49643249e66D120f23d9D9EB',
        SIX_MONTH: '0x9929Fd06b721cfb608e360Bdd4118bBbaB032Ab6',
        TWELVE_MONTH: '0x50Fd9fC6cC12A13a1F8f4efA042D20A1cEE13684',
      },
      REWARD_CLAIMER_CONTRACTS: {
        ONE_MONTH: '0xEe839edf2e793b31221c6fB35aa3f9e8B29Ae96c',
        THREE_MONTH: '0x4B430a293B09e62Bb1d17E283DedfA5fcA774968',
        SIX_MONTH: '0xC94214bB8B578FfB891F909d9CF9A31e800e4f16',
        TWELVE_MONTH: '0xB13fc1e8a87FB8884889a8BcE312Af45a44d8c25',
      },
      CHEEKS: '0x3c2deab5934dec3648e2952d03c7f3c0e5e7dd65',
      LIQUIDITY_LOCKDROP: '0x537A69CFe09D4af2DEff819F15E7106Ff32Ae98A',
      PROXY_FARMER: '0xDF0120E692FfaCBdCF5D87f9E72C6F5Aee4aD35e',
      PRE_MINING_REWARD_CLAIMER: '0xD4AA2DC01d723D4c394dBd3F4aF3aD992F980208',
      SYNAPSE_ASSETS: [
        '0x6270B58BE569a7c0b8f47594F191631Ae5b2C86C',
        '0xd6dAb4CfF47dF175349e6e7eE2BF7c40Bb8C05A3',
        '0xDCbacF3f7a069922E677912998c8d57423C37dfA',
        '0xCD6f29dC9Ca217d0973d3D21bF58eDd3CA871a86',
        '0x078dB7827a5531359f6CB63f62CFA20183c4F10c',
      ],
      MASTERCHEF_UI_HELPER: '0x62C85cAa214c377F21aC0CE483b24176f4C856EC',
      KLAY_FUT: '0xBCa08541269a6A35F9ad04920cC3461C0598A057',
      SKOKOA_FUT: '0x90BbAd8573C06f2d013870A5e9ab2833a979d252',
      SKOKOA_FUT_REDEEMER: '0x9199831987Fab292E56e69Fb9f04B01115680a1b',
    },
  },
  [CustomMarket.proto_baobab]: {
    chainId: 1001,
    logo: logos.aavev2Logo,
    activeLogo: logos.aavev2ActiveLogo,
    aTokenPrefix: 'A',
    enabledFeatures: {
      faucet: true,
      governance: true,
      staking: true,
      incentives: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0xd2219fc9B300cdAb24543C1876F635D5CAd57a45',
      LENDING_POOL: '0x085be3bE0F71Fb577eb4218D7E97a468E8A96494',
      WETH_GATEWAY: '0x44A567d054A25c17a4Fb07d0Fb0068b27aadd67F',
      MULTIFEE_DISTRIBUTION: '0x2709be43B61E47F8c8d33d83edfd4D79A647203A',
      CHEFS_INCENTIVE_CONTROLLER: '0x079d8F1b1D223f4e7CE0666454B4A89ac63AB650',
      MASTERCHEF: '0xd116B941496851bf65ef07eE3d1F4c532cF142Ab',
      MAIN_TOKEN: '0x974d71D3E4989A667378DC55EF8672BB5b3dA975',
      LP_TOKEN: '0x99db8BcEfc2472A5AA06b7D537879A4C145cFC8c',
      VE: '0xa6911bb8d1026a7dD976C3690aD7400fDA8F90E1',
      CLAIMSWAP_ROUTER: '0x9e85cebe422B98c3c603bF59E6c07aB76CC095C4',
      MULTIREWARDER: '0x87678Cd1ecEe00c4cBbCa37DB08CBdfA409eFD6A',
      LOCKDROP_CONTRACTS: {
        ONE_MONTH: '0x63C3D6B37A7e71DBFab31d65315992E8Da99Bd26',
        THREE_MONTH: '0x951Ad2678CA6Cfb154B60ffbC20a246AeeaE8fd9',
        SIX_MONTH: '0x1B09c7865a000b54f1787AB619cBEffd5Ca14224',
        TWELVE_MONTH: '0xaAEe2Fa8487ff9B1B9a63E26276906A22B4BDafd',
      },
      LIQUIDITY_LOCKDROP: '0xf7ac5d0339EB1ef68512B3C3da63506B823B01a7',
      SYNAPSE_ASSETS: [
        '0x2E166055e4B183FB11E2AE32E4ea67a7d41ce294',
        '0xd0b8D24B5e394cf2e0bc1d5eFB38b8855fB65994',
        '0x445b07B00fBd81dFbd3dcA6d136739F8b94c7ae5',
        '0x22Fedd7Ca728e9D6195D003ad380F2a202F19bE9',
        '0x5E209E072AE0Ee431e861473cCedaf5de163FcEb',
      ],
    },
  },
  [CustomMarket.proto_kovan]: {
    chainId: ChainId.kovan,
    logo: logos.aavev2Logo,
    activeLogo: logos.aavev2ActiveLogo,
    aTokenPrefix: 'A',
    enabledFeatures: {
      faucet: true,
      governance: true,
      staking: true,
      incentives: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x63A98a54EBA3Cc3029054A0493cEdE47341394AD'.toLowerCase(),
      LENDING_POOL: '0x0e7479FC79aE4719bbdfe263172C87Ccc4A30FEE',
      WETH_GATEWAY: '0x7dd81070f73ae4B1fe544bE62810d822Dd22453B',
      MULTIFEE_DISTRIBUTION: '0x3D1e04007A99Ee5a322A59a7bF575Bcc211bF825',
      CHEFS_INCENTIVE_CONTROLLER: '0xeEe80c54479d1232abB8C94E2C79540ea01B2b03',
      MASTERCHEF: '0xEDB365E2945eeE2eE73F6C1c33F2bf2f0722ecf1',
      PODL: '0x56a05c14fdAd2C6B57F448A373AC24C55e40cF94',
      MAIN_TOKEN: '0xf1075997248356815e6a521cf741Ccc955F86090',
    },
  },
  [CustomMarket.proto_mainnet]: {
    chainId: ChainId.mainnet,
    logo: logos.aavev2Logo,
    activeLogo: logos.aavev2ActiveLogo,
    aTokenPrefix: 'A',
    enabledFeatures: {
      governance: true,
      staking: true,
      liquiditySwap: true,
      collateralRepay: true,
      incentives: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0xB53C1a33016B2DC2fF3653530bfF1848a515c8c5'.toLowerCase(),
      LENDING_POOL: '0x7d2768dE32b0b80b7a3454c06BdAc94A69DDc7A9',
      WETH_GATEWAY: '0xcc9a0B7c43DC2a5F023Bb9b738E45B0Ef6B06E04',
      REPAY_WITH_COLLATERAL_ADAPTER: '0x498c5431eb517101582988fbb36431ddaac8f4b1',
      SWAP_COLLATERAL_ADAPTER: '0x135896DE8421be2ec868E0b811006171D9df802A',
    },
  },
  [CustomMarket.amm_kovan]: {
    chainId: ChainId.kovan,
    logo: logos.ammLogo,
    activeLogo: logos.ammActiveLogo,
    aTokenPrefix: 'AAMM',
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x67FB118A780fD740C8936511947cC4bE7bb7730c'.toLowerCase(),
      LENDING_POOL: '0x762E2a3BBe729240ea44D31D5a81EAB44d34ef01',
      WETH_GATEWAY: '0xA61ca04DF33B72b235a8A28CfB535bb7A5271B70',
      FAUCET: '0x600103d518cC5E8f3319D532eB4e5C268D32e604',
    },
  },
  [CustomMarket.amm_mainnet]: {
    chainId: ChainId.mainnet,
    logo: logos.ammLogo,
    activeLogo: logos.ammActiveLogo,
    aTokenPrefix: 'AAMM',
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0xacc030ef66f9dfeae9cbb0cd1b25654b82cfa8d5'.toLowerCase(),
      LENDING_POOL: '0x7937d4799803fbbe595ed57278bc4ca21f3bffcb',
      WETH_GATEWAY: '0xcc9a0B7c43DC2a5F023Bb9b738E45B0Ef6B06E04',
    },
  },
  [CustomMarket.proto_mumbai]: {
    chainId: ChainId.mumbai,
    logo: logos.aaveLogo,
    activeLogo: logos.aaveActiveLogo,
    subLogo: logos.polygon,
    aTokenPrefix: 'AM',
    enabledFeatures: {
      incentives: true,
      faucet: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x178113104fEcbcD7fF8669a0150721e231F0FD4B'.toLowerCase(),
      LENDING_POOL: '0x9198F13B08E299d85E096929fA9781A1E3d5d827',
      WETH_GATEWAY: '0xee9eE614Ad26963bEc1Bec0D2c92879ae1F209fA',
      FAUCET: '0x0b3C23243106A69449e79C14c58BB49E358f9B10',
    },
  },
  [CustomMarket.proto_matic]: {
    chainId: ChainId.polygon,
    logo: logos.aaveLogo,
    activeLogo: logos.aaveActiveLogo,
    subLogo: logos.polygon,
    aTokenPrefix: 'AM',
    enabledFeatures: {
      liquiditySwap: true,
      incentives: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0xd05e3E715d945B59290df0ae8eF85c1BdB684744'.toLowerCase(),
      LENDING_POOL: '0x8dFf5E27EA6b7AC08EbFdf9eB090F32ee9a30fcf',
      WETH_GATEWAY: '0xbEadf48d62aCC944a06EEaE0A9054A90E5A7dc97',
      SWAP_COLLATERAL_ADAPTER: '0x35784a624D4FfBC3594f4d16fA3801FeF063241c',
    },
  },
  [CustomMarket.proto_fuji]: {
    chainId: ChainId.fuji,
    logo: logos.aaveLogo,
    activeLogo: logos.aaveActiveLogo,
    subLogo: logos.avalanche,
    aTokenPrefix: 'AAVA',
    enabledFeatures: {
      faucet: true,
      incentives: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x7fdC1FdF79BE3309bf82f4abdAD9f111A6590C0f'.toLowerCase(),
      LENDING_POOL: '0x76cc67FF2CC77821A70ED14321111Ce381C2594D',
      WETH_GATEWAY: '0x1648C14DbB6ccdd5846969cE23DeEC4C66a03335',
      FAUCET: '0x90E5BAc5A98fff59617080848959f44eACB4Cd7B',
    },
  },
  [CustomMarket.proto_avalanche]: {
    chainId: ChainId.avalanche,
    logo: logos.aaveLogo,
    activeLogo: logos.aaveActiveLogo,
    subLogo: logos.avalanche,
    aTokenPrefix: 'AV',
    enabledFeatures: {
      liquiditySwap: true,
      incentives: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0xb6A86025F0FE1862B372cb0ca18CE3EDe02A318f'.toLowerCase(),
      LENDING_POOL: '0x4F01AeD16D97E3aB5ab2B501154DC9bb0F1A5A2C',
      WETH_GATEWAY: '0x8a47F74d1eE0e2edEB4F3A7e64EF3bD8e11D27C8',
      SWAP_COLLATERAL_ADAPTER: '0x2EcF2a2e74B19Aab2a62312167aFF4B78E93B6C5',
    },
  },
} as const;

import React from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

import staticStyles from '../liquidityMining/LiquidityMiningCard/style';
import TooltipIcon from '../../images/tooltip.svg';
import styled from 'styled-components';
import Logo from '../basic/Logo';
import { useIntl } from 'react-intl';
import messages from '../liquidityMining/LiquidityMiningBreakdown/messages';

const APRSeparation = styled.div`
  grid-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const IndividualContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  justify-content: flex-end;
`;

const ToolTipText = styled.div`
  color: white;
  font-size: 14px;
  text-align: right;
`;

const APRContainer = styled.div`
  display: flex;
  grid-gap: 4px;
  justify-content: flex-end;
`;

interface LiquidityMiningBreakdownProps {
  baseApr: number;
  klayApr: number;
  boostedApr: any;
  showTypingIndicator?: boolean;
}

export default function TooltipCoinBreakdown({
  baseApr,
  klayApr,
  boostedApr,
  showTypingIndicator,
}: LiquidityMiningBreakdownProps) {
  const intl = useIntl();
  return (
    <div className={classNames('LiquidityMiningCard')}>
      <img src={TooltipIcon} alt={'Tooltip icon'} data-tip={'velp'} data-for={`velp-tooltip`} />

      {/* @ts-ignore */}
      <ReactTooltip
        className="LiquidityMiningBreakdown__tooltip"
        id={`velp-tooltip`}
        effect="solid"
      >
        <APRSeparation>
          <IndividualContainer>
            <ToolTipText>{intl.formatMessage(messages.baseAPR)}:</ToolTipText>
            <ToolTipText>{intl.formatMessage(messages.veBoosterAPR)}:</ToolTipText>
            {klayApr !== 0 ? (
              <ToolTipText>{intl.formatMessage(messages.klayAPR)}:</ToolTipText>
            ) : null}
          </IndividualContainer>
          <IndividualContainer>
            <APRContainer>
              <Logo height={16} width={16} />
              <ToolTipText>KLAP</ToolTipText>
              <ToolTipText style={{ color: '#00CC88' }}>
                {(baseApr * 100).toLocaleString('en-us', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </ToolTipText>
              <ToolTipText style={{ color: '#B9C0CA' }}>%</ToolTipText>
            </APRContainer>
            <APRContainer>
              <Logo height={16} width={16} />
              <ToolTipText>KLAP</ToolTipText>
              <ToolTipText style={{ color: '#00CC88' }}>
                {isNaN(boostedApr)
                  ? boostedApr
                  : (boostedApr * 100).toLocaleString('en-us', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </ToolTipText>
              <ToolTipText style={{ color: '#B9C0CA' }}>%</ToolTipText>
            </APRContainer>
          </IndividualContainer>
        </APRSeparation>
        <p style={{ textAlign: 'right', marginTop: 8 }}>
          <b>{intl.formatMessage(messages.boosterNote)}</b>
        </p>
        {showTypingIndicator && (
          <p style={{ textAlign: 'right', marginTop: 8 }}>
            <b>{intl.formatMessage(messages.showTypingIndicator)}</b>
          </p>
        )}
      </ReactTooltip>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .LiquidityMiningBreakdown__tooltip {
          background-color: #000000;
          border-radius: 16px;
          max-width: 330px;
          opacity: 1;
        }

        .TokenIconTooltip img {
          margin-right: 0px;
        }
        .LiquidityMiningCard__valueWithTooltip {
          display: flex;
          align-items: center;
          grid-gap: 5px;
          img {
            height: 15px;
            width: 15px;
            margin-bottom: 3px;
          }
        }
      `}</style>
    </div>
  );
}

import { useWeb3React } from '@web3-react/core';
import { providers, Contract } from 'ethers';
import { getProvider } from '../../helpers/config/markets-and-network-config';
import { useProtocolDataContext } from '../../libs/protocol-data-provider';
import ChefABI from '../../contracts/ChefIncentivesController.json';
import { useUserWalletDataContext } from '../../libs/web3-data-provider';
import { AbiItem } from 'caver-js';
import waitForTxn from '../../helpers/wait-for-txn';
// @ts-ignore
import { prepare } from 'klip-sdk';
import { useKlipContext } from '../../components/wrappers/ScreensWrapper';
import _ from 'lodash';

export const useServices = () => {
  const setShowQRCode = useKlipContext();
  const { currentAccount: userId, currentProviderName } = useUserWalletDataContext();
  const { currentMarketData, chainId } = useProtocolDataContext();
  const { library: provider } = useWeb3React<providers.Web3Provider>();
  const sendObject = {
    from: (window as any).klaytn ? (window as any).klaytn?.selectedAddress : null,
    gas: 1800000,
  };
  const mmObject = { gasPrice: 250000000000 };

  const chefService = (transaction?: object) => {
    if (currentProviderName === 'kaikas') {
      // @ts-ignore
      const caver = new Caver(provider!.provider);
      return new caver.klay.Contract(
        ChefABI as AbiItem[],
        currentMarketData.addresses.CHEFS_INCENTIVE_CONTROLLER!
      ) as any;
    } else if (currentProviderName === 'klip') {
      return prepare.executeContract({
        bappName: 'Klap',
        to: currentMarketData.addresses.CHEFS_INCENTIVE_CONTROLLER!,
        ...transaction,
      });
    }
    const topHolderSigner = provider ? provider!.getSigner(userId) : getProvider(chainId);
    return new Contract(
      currentMarketData.addresses.CHEFS_INCENTIVE_CONTROLLER!,
      ChefABI,
    // @ts-ignore
      topHolderSigner
    );
  };

  const voteTokens = async (tokenId: string, pools: string[], weights: number[]) => {
    try {
      if (currentProviderName === 'kaikas') {
        const returnVal = await chefService()
          .methods.vote(tokenId, pools, weights)
          .send(sendObject);
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await chefService({
          value: '0',
          abi: JSON.stringify(_.find(ChefABI, (func) => func.name === 'vote')),
          params: JSON.stringify([tokenId, pools, weights]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        console.log('vote', [tokenId, pools, weights]);
        const vote = await chefService().vote(tokenId, pools, weights, mmObject);
        await vote.wait();
      }
    } catch (e) {}
  };

  const unvoteTokens = async (tokenId: string) => {
    try {
      if (currentProviderName === 'kaikas') {
        const returnVal = await chefService().methods.remove_vote(tokenId).send(sendObject);
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await chefService({
          value: '0',
          abi: JSON.stringify(_.find(ChefABI, (func) => func.name === 'remove_vote')),
          params: JSON.stringify([tokenId]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        const vote = await chefService().remove_vote(tokenId, mmObject);
        await vote.wait();
      }
    } catch (e) {}
  };

  return {
    voteTokens,
    unvoteTokens,
  };
};
